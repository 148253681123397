import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';

function TestimonialsCarousel() {

  const [active, setActive] = useState(0);
  const [autorotate, setAutorotate] = useState(true);
  const [autorotateTiming] = useState(7000);
  const [items] = useState([
    {
      quote: '“ Le Groupe G7 a développé en 2009 avec CPI un outil de trésorerie doté des meilleures fonctionnalités, sécurisé et comparable aux grandes marques concurrentes du marché. En 2016, nous avons 80 utilisateurs pour 180 sociétés et 300 comptes bancaires. En outre, nous pouvons compter quotidiennement sur une maintenance humaine toujours disponible. Je remercie CPI pour son grand professionnalisme.  “',
      team: 'Trésorier - Groupe G7',   
    },
    {
      quote: '“ Nous avons trouvé la robustesse d\'un logiciel adapté à nos besoins et à l\'évolution des techniques numériques ainsi que l\'agilité d\'une équipe CPI professionnelle dont l\'engagement est sans faille depuis maintenant plus de 10 ans.“',
      team: 'Direction Trésorerie - Bouygues Construction',
    },
    {
      quote: '“ Une équipe réactive toujours à la pointe de son activité qui arrive à lier ses connaissances des métiers de la Trésorerie à nos définitions de besoins spécifiques. CPI propose de nombreuses solutions logicielles développées avec une approche pragmatique des besoins des Trésoriers.“',
      team: 'Group Treasury Back-Office - Unibail-Rodamco',
    }
  ]);

  const testimonials = useRef(null);

  const stopAutorotate = () => {
    setAutorotate(null);
  }

  const heightFix = () => {
    if (testimonials.current.children[active]) {
      testimonials.current.style.height = testimonials.current.children[active].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    if (!autorotate) return
    const interval = setInterval(() => {
      setActive(active + 1 === items.length ? 0 : active => active + 1)
    }, autorotateTiming)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, autorotate])

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <section className="relative" id='temoignages'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
            <h2 className="h2 mb-4 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Plus de 1100 Clients utilisent nos logiciels </h2>
          </div>
          {/* Carousel area */}
          <div className="max-w-5xl mx-auto">
            {/* Carousel */}
            <div className="relative" data-aos="fade-down">
              {/* Testimonials */}
              <div className="relative flex items-start z-10 transition-all duration-300 ease-in-out" ref={testimonials}>
                {items.map((item, index) => (
                  <Transition
                    key={index}
                    show={active === index}
                    appear={true}
                    className="w-full text-center px-12 py-8 mx-4 md:mx-0"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterStart="opacity-0 -translate-y-8"
                    enterEnd="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveStart="opacity-100 translate-y-0"
                    leaveEnd="opacity-0 translate-y-8"
                  >
                    <div className="inline-flex mb-3"></div>
                    <blockquote className="text-xl md:text-2xl font-medium text-gray-800 dark:text-gray-400 mb-4">{item.quote}</blockquote>
                    <div className="font-medium text-lg">
                      <p className="text-rose-700 underline text-center">{item.team}</p> 
                    </div>
                  </Transition>
                ))}
              </div>

              {/* Skewed borders */}
              <div className="absolute inset-0 transform -skew-x-3 border-2 border-gray-200 dark:border-gray-800 pointer-events-none" aria-hidden="true"></div>
              {/* Arrows */}
              <div className="absolute inset-0 flex items-center justify-between">
                <button
                  className="relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group transform -translate-x-2 md:-translate-x-1/2 bg-rose-700 hover:bg-rose-600 dark:bg-gray-800 dark:hover:bg-rose-700 dark:hover:bg-opacity-25 transition duration-150 ease-in-out"
                  onClick={() => { setActive(active === 0 ? items.length - 1 : active - 1); stopAutorotate(); }}
                >
                  <span className="sr-only">Previous</span>
                  <svg className="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-rose-700 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
                  </svg>
                </button>
                <button
                  className="relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group transform translate-x-2 md:translate-x-1/2 bg-rose-700 hover:bg-rose-600 dark:bg-gray-800 dark:hover:bg-rose-700 dark:hover:bg-opacity-25 transition duration-150 ease-in-out"
                  onClick={() => { setActive(active === items.length - 1 ? 0 : active + 1); stopAutorotate(); }}
                >
                  <span className="sr-only">Next</span>
                  <svg className="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-rose-700 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
export default TestimonialsCarousel;