import React from 'react';

function BlogSingleFOVI() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down"> Qu’est-ce que l’escroquerie aux faux ordres de virement (FOVI) ? </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <h3 className="mb-4 h4 dark:text-gray-300 ">La fraude aux faux ordres de virement, une menace majeure pour les entreprises</h3>
                <figure className="py-8">
                  <img className="w-full " src={require('../images/fovi-header.jpg').default}  alt="News inner" />
                </figure>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Vous connaissez sans doute la « fraude au président », mais connaissez-vous celle liée au changement de RIB ? </h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Cette escroquerie financière vise à usurper l’identité d’un fournisseur ou d’un employé afin de communiquer de nouvelles coordonnées bancaires détenues par les cybercriminels sur lesquelles il faut effectuer un règlement ou un virement de salaire. Pour procéder à ce détournement, les escrocs usent de tromperie par téléphone ou courriel.</p>


                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'> En 2021, les forces de l’ordre ont relevé 517 affaires de FOVI pour un montant total de 101,2 millions d’euros, incluant un cas exceptionnel ayant causé un préjudice de 33 millions d’euros.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Observatoire de la sécurité des moyens de paiement</p>
                    </div>
                </div>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">SI VOUS ÊTES VICTIME D’UNE ESCROQUERIE</h4>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>Identifiez tous les virements frauduleux exécutés et bloquez ceux en instance ou à venir en destination de l’escroc.</li>
                  <li>Informez votre hiérarchie et les services concernés puis contactez immédiatement votre banque afin de demander le retour des fonds ou la gelée des opérations bancaires.</li>
                  <li>Déposez plainte auprès des autorités compétentes et présentez toutes les preuves récoltées. La banque exigera le dépôt de plainte.</li>
                  <li>Enfin, signalez la fraude aux tiers. </li>
                </ul>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">LES MESURES PRÉVENTIVES</h4>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le principe des 4 yeux est un protocole de sécurité que vous retrouvez dans les logiciels CPISoftware, toute modification de paramétrage doit être validée par une seconde personne permettant ainsi de responsabiliser deux personnes au sein du service. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">CPI intègre dans ses logiciels un automate d’authentification des coordonnées bancaires des tiers par le biais d’un prestataire spécialisé dans la lutte contre la fraude aux virements bancaires.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Contrôlez l’identité du fournisseur en comparant les mails, le numéro de téléphone, les factures sur la base des précédents échanges. Utilisez des procédures de double signature ou de contre-appel.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Sensibilisez les collaborateurs au risque : contrôle continu de la base tiers, diffusion des procédures d’authentification des émetteurs et de vérification des demandes de virement imprévus.  </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleFOVI;