import React from 'react';

function CiliarisComponent() {
  return (
    <section>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Line */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
             <h3 className="h3 mb-8 dark:text-gray-300 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Optez pour une solution de gestion de vos prélèvements SEPA fiable, souple et complète.</h3>
              <p className=" text-xl text-gray-600 dark:text-gray-400" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Avec CILIARIS, créez vos échéanciers de prélèvements, traitez vos impayés de prélèvements SEPA XML et bien plus encore.<br/> CILIARIS est une solution complète et accessible, rendant la gestion de vos mandats plus facile.</p>
          </div>

          {/* Features */}
          <div className="pt-8 max-w-xl mx-auto md:max-w-none space-y-20">

            {/* 1st Feature */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0">
              
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h2 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Bénéficiez d'une gestion automatique du cycle de vie de vos mandats SEPA :</h2>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                   <li>Importation et migration de vos autorisations de prélèvements.</li>
                   <li>Attribution de la référence unique de mandat personnalisée (RUM)</li>
                   <li>Sécurité de gestion avec double contrôle.</li>
                   <li>Dématérialisation de vos mandats signés</li>
                   <li>Impression des pré-notifications</li>
                   <li>Interfaçage de vos applications financières avec CILIARIS.</li>   
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none -z-10" aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600" src={require('../images/Ciliaris/1.jpg').default} width="350" alt="Ciliaris 1"/>
                </div>
              </div>
            </div>
            {/* 2nd Feature*/}
            <div className="flex flex-col-reverse md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0 mt-6">  
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Gérez simplement vos portefeuilles de prélèvements :</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                    <li>Création et saisie facilitée de vos prélèvements.</li>
                    <li>Gestion simplifiée de vos échéances de prélèvements.</li>
                    <li>Importation et vérification de fichiers de prélèvements.</li>
                    <li>Conversion simple et paramétrable de vos prélèvements.</li>   
                    <li>Génération de fichiers ad hoc.</li>
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none-z-10 " aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600"  src={require('../images/Ciliaris/2.jpg').default} width="350" alt="Ciliaris 2" />
                </div>
              </div>
            </div>
            {/* 3rd Feature */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0">
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Traitez sereinement vos<br/>impayés :</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                    <li>Intégration en temps réel.</li>
                    <li>Rapprochement automatique des PSR.</li>
                    <li>Rapprochement automatique avec les impayés.</li>
                    <li>Affichage complet des impayés.</li>
                    <li>Mise à jour automatique du statut du mandat et du prélèvement.</li>
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none -z-10" aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600" src={require('../images/Ciliaris/03.jpg').default} width="350" alt="Ciliaris 3"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CiliarisComponent;
