import React from 'react';

function HeroBlog() {
  return (
    <section className="relative">

      {/* Background image */}
      <div className="absolute inset-0 h-128 pt-16 box-content -z-1">
        <img className="absolute inset-0 w-full h-full object-cover opacity-50" src={require('../images/Societe/Societe-Hero.jpg').default} width="1440" height="577" alt="About" />
        <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900" aria-hidden="true"></div>
      </div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-16">
          <div className="max-w-3xl mx-auto text-center pt-12 pb-16">
            <h1 className="h1 font-libre mb-4">L'histoire de CPI</h1>
            <p className="text-2xl text-gray-600 dark:text-gray-300">Depuis plus de 30 ans, CPI facilite et sécurise les échanges entre les entreprises et leurs partenaires banquiers.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroBlog;