import React from 'react';

import Header from '../partials/Header';
import HeroBlog from '../partials/HeroBlog';
import AboutFeatures from '../partials/AboutFeatures';
import CarouselSociete from '../partials/CarouselSociete';
import FeaturesAnimation from '../partials/FeaturesAnimation';
import Footer from '../partials/Footer';

function Societe() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">

        {/*  Page sections */}
        <HeroBlog />
        <AboutFeatures />
        <CarouselSociete />
        <FeaturesAnimation />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}
export default Societe;