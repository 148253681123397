// import React from 'react';
import { Link } from 'react-router-dom';




function Footer() {
  return (
<footer className="relative">
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px">
            {/* Footer illustration */}
            <div className="pointer-events-none -z-1" aria-hidden="true">
                <svg className="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 dark:opacity-40" width="800" height="264" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="400" cy="400" r="400" fill="url(#footerglow_paint0_radial)" fillOpacity=".4" />
                    <defs>
                        <radialGradient id="footerglow_paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 0 400) scale(315.089)">
                            <stop stopColor="#e11d48" />
                            <stop offset="1" stopColor="#e11d48" stopOpacity=".01" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>
            {/* Top area: Blocks */}
            <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">

                {/* 1st block */}
                <div className="md:col-span-2 lg:col-span-3">
                    {/* Logo */}
                    <div className="mt-16 mr-6">
					{/* Logo */}
						<Link to="/" className="block" aria-label="CPI">
							<img
								src={require('../images/logo.png').default}
								width="100" height="25"
								alt="CPI logo footer"
							/>
						</Link>
					</div>
                </div>
                {/* 2nd, 3rd, 4th and 5th blocks */}
                <div className="md:col-span-10 lg:col-span-9 grid grid-cols-2 md:grid-cols-3 gap-8 grid-rows-3 grid-flow-col md:grid-rows-none md: grid-flow-row">
                    {/* 2nd block */}
                    <div className="text-sm  row-span-3 md:row-auto">
                        <h6 className="font-medium uppercase mb-2">Produits</h6>
                        <ul className='text-gray-600 dark:text-gray-400 '>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/opencash">OpenCash</Link>
                            </li>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/cubicus">Cubicus</Link>
                            </li>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/hepatus">Hepatus</Link>
                            </li>
							<li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="ciliaris">Ciliaris</Link>
                            </li>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/exodon">Exodon</Link>
                            </li>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/adioryx">Adioryx</Link>
                            </li>
						
                        </ul>
                    </div>
                    {/* 3rd block */}
                    <div className="text-sm">
                        <h6 className="font-medium uppercase mb-2">Ressources</h6>
                        <ul className='text-gray-600 dark:text-gray-400 '>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/blog">Blog</Link>
                            </li>
                            <li className="mb-1">
                                <a className="hover:text-rose-700 transition duration-150 ease-in-out" href="https://cpiteamviewer.s3.eu-west-3.amazonaws.com/TeamViewerQS_fr.exe" >TeamViewer</a>
                            </li>
                            <li className="mb-1">
                                <a className="hover:text-rose-700 transition duration-150 ease-in-out" href="https://cpiteamviewer.s3.eu-west-3.amazonaws.com/TeamViewerQJ.exe">TeamViewer Meeting</a>
                            </li>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/mot-de-passe-du-jour">Mot de passe du jour</Link>
                            </li>
                        </ul>
                    </div>
                    {/* 4th block */}
                    <div className="text-sm row-span-2 md:row-auto">
                        <h6 className="font-medium uppercase mb-2">Entreprise</h6>
                        <ul className='text-gray-600 dark:text-gray-400 '>
                            <li className="mb-1">
                                <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/societe/">A propos de CPI</Link>
                            </li>
                            <li className="mb-1">
                                <a className="hover:text-rose-700 transition duration-150 ease-in-out" href="societe/#partners">Partenaires & Revendeurs</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Bottom area */}
            <div className="md:flex md:items-center md:justify-between">
                {/* Social links */}
                <ul className="flex mb-4 md:order-2 md:ml-4 md:mb-0">
                    <li>
                        <a className="flex justify-center items-center text-white bg-rose-700 dark:text-rose-700 dark:bg-gray-800 hover:text-rose-700 hover:bg-rose-600 rounded-full transition duration-150 ease-in-out" href="https://twitter.com/CPI_Software" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(8,8)">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li className="ml-4">
                        <a className="flex justify-center items-center text-white bg-rose-700 dark:text-rose-700 dark:bg-gray-800 hover:text-rose-700 hover:bg-rose-600 rounded-full transition duration-150 ease-in-out" href="https://www.linkedin.com/company/c.p.i." target="_blank" rel="noopener noreferrer" aria-label="Linkedin">
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                            </svg>
                        </a>
                    </li>
                    <li className="ml-4">
                        <a className="flex justify-center items-center text-white bg-rose-700 dark:text-rose-700 dark:bg-gray-800 hover:text-rose-700 hover:bg-rose-600 rounded-full transition duration-150 ease-in-out" href="https://www.youtube.com/channel/UCYJ3nxktPRz1AcwzZpyZPmA" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <g transform='translate(8.5,8.5)'>
                                   <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                </g>
                            </svg>
                        </a>
                    </li>
                </ul>
                {/* Middle links */}
                <div className="text-sm md:order-1 text-gray-600 dark:text-gray-400 b-2 md:mb-0 ">
                    <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/privacy">Privacy Policy</Link>
                </div>
                {/* Copyrights note */}
                <div className="text-gray-600 dark:text-gray-400 text-sm mr-4">&copy; 2024 CPI. All rights reserved.</div>
            </div>
        </div>
    </div>
</footer>
  );
}

export default Footer;
