import React from 'react';

function BlogSingleOpenCash() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">Reprenez le contrôle de l'ensemble de vos flux bancaires. </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <h3 className="mb-4 h4 dark:text-gray-300 ">OpenCash, qu’est-ce que c’est ?</h3>
                <p className="mb-8 text-gray-600 dark:text-gray-400 ">OpenCash est la première solution de TMS Full Web de CPI qui concentre l'ensemble de son expertise dans le domaine de la communication bancaire, les moyens de paiements, la trésorerie et les Pouvoirs bancaires pour les entreprises.</p>
                
                <figure className="py-8">
                  <img className="w-full " src={require('../images/OpenCash/cash.png').default}  alt="News inner" />
                </figure>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Avec notre logiciel Full SaaS, reprenez le contrôle de l'ensemble de vos flux bancaires</h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">CPI a concentré plus de 30 ans d'expérience dans l’innovation logicielle et la gestion des flux bancaires dans la plateforme la plus aboutie à ce jour.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">OpenCash est une solution unique sur le marché des logiciels financier qui propose : </p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>Une solution intégrée qui répond aux attentes des métiers de la direction financière</li>
                  <li>Une plateforme flexible, conçue pour ses différents utilisateurs au plus près de leurs besoins.</li>
                  <li>Une sécurité maximale, pour l'ensemble de vos saisies et échanges.</li>
                  <li>Une couverture fonctionnelle large, du front office au Back office sans oublier les fonctions transverses de pilotage.</li>
                  <li>Un logiciel Full web, accessible partout en mode SaaS via un simple navigateur ou via App mobile iOS et Android.</li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleOpenCash;