import React from 'react';

function BlogSingleNew() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">CPI change son site internet </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <p className="mb-4  dark:text-gray-300 ">Plus simple et plus proche de vous, notre nouveau site vous  aide à  mieux  trouver le bon outil  pour sécuriser et simplifier vos flux bancaires.</p>
                <p className="mb-8 text-gray-600 dark:text-gray-400 ">C’est avec beaucoup d’enthousiasme que CPI vous annonce l’ouverture de son nouveau site web accessible à la même adresse : <a href='http://www.cpisoftware.eu/'>http://www.cpisoftware.eu/ </a></p>
                
                <figure className="py-8">
                  <img className="w-full " src={require('../images/newwebsite.jpg').default}  alt="News inner" />
                </figure>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Avec notre logiciel Full SaaS, reprenez le contrôle de l'ensemble de vos flux bancaires </h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Après des semaines de développement, nous sommes ravis de pouvoir vous présenter la vitrine de notre société sur la toile. Ce site est conçu pour nos clients, ainsi que pour nos partenaires et revendeurs. Il permettra d'accompagner notre essor commun et de faciliter votre prise de décision. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Notre nouveau site web est responsive. En effet, il fonctionne avec tout type de supports (PC, tablette, mobile). Ainsi, la taille des objets (photos, textes, éléments visuels, etc.). Il s'adapte à la taille de l'écran sur lequel ils sont visibles. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Vous y trouverez des informations détaillées de nos différents logiciels, ainsi que l'historique de CPI  et toutes  les actualités de notre société.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Vous pourrez nous contacter via la rubrique contact.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Désormais. Vous n’aurez plus besoin de contacter le service support pour obtenir le mot de passe du jour, car il se trouve sur le site internet dans la rubrique ressources /Mot de passe du jour✨.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">En espérant que vous apprécierez ce nouveau design, nous vous souhaitons une belle découverte du site.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleNew;