import React from 'react';
import { Link } from 'react-router-dom';

function BlogSingleAmaury() {

    return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">Choix Stratégique : Comment Cubicus a répondu aux attentes du Groupe Amaury ? </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/Amaury.jpg').default}  alt="News inner" />
                </figure>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8">Présentation de l’entreprise :</h3>

                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>Amaury</strong> domine le domaine du sport avec sa marque phare, <strong>"L'Équipe"</strong>, présente à la fois sur papier, en ligne, sur mobile et à la télévision avec la chaîne du même nom. Chaque mois, 34 millions de Français s'engagent avec cette marque. Le groupe Amaury possède également des titres emblématiques tels que <strong>France Football</strong>, <strong>L'Équipe Magazine</strong> et <strong>Vélo Magazine</strong>.</p>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>Nous avons réalisé une étude de marché avec plusieurs concurrents, et notre choix s'est naturellement porté sur Cubicus, car c'était la solution la plus intéressante pour nous en termes de rapport coût/investissement. Nous avons collaboré étroitement avec l'équipe CPI pour le développement, l'appropriation et la personnalisation du logiciel.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Charles PAUFLER, Directeur de la trésorerie<br/>Groupe AMAURY</p>
                    </div>
                </div>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>La relation avec l'équipe CPI était étroite, nous sommes restés fidèles parce que nous y trouvions une écoute attentive. Cubicus est un logiciel qui offre une facilité de personnalisation pour le client, contrairement à d'autres solutions plus imposantes où le client peut se sentir moins pris en compte. Ici, nous avons bénéficié d'une réelle écoute, et c'est le logiciel qui s'est adapté à nos besoins.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Charles PAUFLER, Directeur de la trésorerie<br/>Groupe AMAURY</p>
                    </div>
                </div>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>Le premier cap pour nous est de passer du logiciel actuel à une évolution tout en préservant au moins les fonctionnalités essentielles qui doivent perdurer dans la prochaine version. Nous attendons avec impatience l'intégration de nouvelles fonctionnalités qui pourraient encore améliorer notre quotidien professionnel. Nous sommes ouverts à toutes les innovations susceptibles d'optimiser notre travail au quotidien.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Charles PAUFLER, Directeur de la trésorerie<br/>Groupe AMAURY</p>
                    </div>
                </div>

                <ul className='pt-8 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Amaury a choisi Cubicus après une étude de marché approfondie, mettant en avant son attrait financier et son adaptabilité aux besoins spécifiques du groupe.</li>
                  <li>La collaboration étroite avec l’équipe CPI a été soulignée, avec une fidélité basée sur l’écoute attentive et la flexibilité de personnalisation.</li>
                  <li>Amaury prévoit de passer à <Link to="/opencash" className="text-rose-700">OpenCash</Link> comme prochain objectif, tout en préservant les fonctionnalités essentielles et en anticipant l’intégration de nouvelles fonctionnalités. </li>
                </ul>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Points forts mis en avant :</h4>
                <ul className='pt-8 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li><Link to="/cubicus" className="text-rose-700">Cubicus</Link> offre un excellent rapport qualité-prix et une personnalisation facilitée, se démarquant par son adaptabilité aux demandes des clients.</li>
                  <li>La fidélité d’Amaury à Cubicus est attribuée à l’écoute attentive de l’équipe CPI, contrairement à des solutions plus rigides sur le marché.</li>
                </ul>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Sources</h4>
                <a className= "text-rose-700" href="https://amaury.com/groupe/qui-sommes-nous" target="_blank" rel="noopener noreferrer">Qui sommes-nous ? - Amaury Groupe</a><br></br>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleAmaury;
