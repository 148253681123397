import React from 'react';
import { Link } from 'react-router-dom';

function BlogSingle4yeux() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">Le principe des 4 yeux</h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/4eyes.jpg').default}  alt="Validation 4 yeux, la puissance de la validation des 4 yeux, double validation 4 yeux, défis de la validation par 4 yeux, histoire de la validation par 4 yeux" />
                </figure>

                <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                  <div className="text-center p-4">
                    <p className='text-gray-600 dark:text-gray-400'> Dans l'Égypte ancienne, le trésor des pharaons était soumis à une rigoureuse discipline financière. Aucune somme ne pouvait quitter <strong>la trésorerie</strong> sans une instruction formelle. En parallèle dans la gestion des entrepôts, chaque sac de blé était rempli sous la surveillance d'un responsable. Ensuite, un scribe consignait minutieusement les quantités entrantes dans les registres de l'entrepôt. Cette méthode nous rappelle le principe du "<strong>contrôle à quatre yeux</strong>", qui visait à <strong>minimiser les risques</strong> de vol de blé et à prévenir les erreurs dans la gestion des stocks. Une approche réfléchie qui transcende les époques pour garantir la sécurité et la précision dans <strong>la gestion des ressources</strong>, plus concrètement la trésorerie.</p>
                  </div>
                </div>

                {/* <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Vous connaissez sans doute la « fraude au président », mais connaissez-vous celle liée au changement de RIB ? </h3> */}
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Aujourd’hui, les enjeux demeurent inchangés quand il s’agit de lutter contre la fraude ou l’erreur. Cependant, il est devenu essentiel de maîtriser et développer les dispositifs permettant de prévenir de tels incidents.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Le principe des quatre yeux :</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le principe des quatre yeux est une pratique de <strong>gestion optimale</strong> des décisions et de gouvernance, il consiste en l’implication de plusieurs parties dans la prise d’une décision ou la validation d’une opération avant qu’elle ne soit validée, afin d’éviter les erreurs ainsi que les <Link to="/blog-post-fovi" className="text-rose-700">fraudes</Link>.</p>
                <p className="mb-2 text-gray-600 dark:text-gray-400 ">Le principe des quatre yeux permet d’avoir :</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li><strong>Un examen à deux niveaux</strong> : deux personnes qui examinent attentivement un processus, une décision ou une activité</li>
                  <li><strong>Deux avis indépendants </strong> : Sollicitation de deux opinions indépendantes pour garantir une diversité de perspectives</li>
                  <li><strong>Deux personnes responsables du résultat</strong> : L’attribution de la responsabilité à deux individus pour renforcer la surveillance</li>
                  <li><strong>Deux personnes pour l’approbation finale</strong> : deux personnes doivent approuver le résultat final pour garantir une décision équilibrée.</li>
                </ul>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Pourquoi l’appelle-t-on le « Principe des 4 yeux » ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Comme le dit le célèbre proverbe français : « Deux têtes valent mieux qu’une », deux opinions indépendantes l’une de l’autre surpassent très souvent une seule, plus concrètement, mieux vaut avoir 2 regards critiques qu’un seul.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Qui utilise le principe des quatre yeux ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le principe des quatre yeux peut être utilisé dans différents contextes, notamment chez les banques lors de la <strong>validation des transactions, l’accès aux données sensibles</strong>, ainsi que <strong>la modification des paramètres de sécurité</strong> pour garantir une sécurité renforcée.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">En impliquant <strong>2</strong> individus dans la validation et la prise de décision, le principe des 4 yeux contribue à une gouvernance plus rigoureuse et à une gestion de risques plus efficace.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La notion des <strong>"quatre yeux"</strong> provient de la volonté de découper toutes les étapes de la chaîne de paiement en segments distincts, visant à intégrer plusieurs collaborateurs tout au long du processus de paiement.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">L’objectif est de renforcer les contrôles pour prévenir toute tentative de fraude ou marge d’erreur humaine. En engageant au moins deux personnes pour vérifier les informations, l’entreprise réduit considérablement le risque d’erreurs aux conséquences potentiellement graves.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Peut-on vraiment compter sur ce principe pour lutter contre la fraude et les erreurs ?</h4>
                <p className="mb-2 text-gray-600 dark:text-gray-400 ">Pour maximiser la sécurité et lutter contre la fraude, <strong>CPI</strong> a mis en place dans <Link to="/opencash" className="text-rose-700">OpenCash</Link> différentes fonctionnalités :</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>Paramétrages avancés sur les profils utilisateurs ;</li>
                  <li>Mise en place d'un workflow de validation des paiements ;</li>
                  <li>Validation des ordres de paiement avec signature numérique individuelle ;</li>
                  <li>Audit et traçabilité des actions réalisées pour chaque utilisateur ;</li>
                  <li>Règles de contrôle sur les flux.</li>
                </ul>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Alors que le principe de validation par 4 yeux est généralement salué pour renforcer la qualité des processus et réduire les erreurs, il est important de reconnaître les défis potentiels liés à cet aspect psychologique fascinant du comportement humain.</p>

                <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                  <div className="text-center p-4">
                    <p className="mb-4 text-gray-600 dark:text-gray-400 ">En 1913, L'ingénieur agricole français Maximilian Ringelmann a mené des études approfondies sur les performances des chevaux, concluant que la force de traction de deux animaux n'était pas le double de celle d'un seul. Élargissant ses recherches aux êtres humains, Ringelmann a découvert que les hommes ne mobilisaient que 85 % de leur puissance individuelle dans une partie de tir à la corde à trois, et seulement 49 % à huit.</p>
                    <p className="mb-4 text-gray-600 dark:text-gray-400 ">En psychologie, ce phénomène est connu sous le nom de <strong>"Social Loafing"</strong> ou <strong>« Paresse Sociale »</strong>, une forme de paresse inconsciente dont nous sommes tous coupables lorsque la performance individuelle n'est pas immédiatement visible.</p>
                    <p className="mb-4 text-gray-600 dark:text-gray-400 ">Cela se révèle dans des situations telles que le sauvetage en eau, où la présence de plusieurs témoins sur le quai peut réduire considérablement les chances d'être secouru. Espérons donc qu'en cas d'urgence, il n'y ait pas une foule de vingt personnes regardant passivement, car la propension à la « Paresse Sociale » diminue la probabilité d'obtenir de l'aide.</p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingle4yeux;
