import React ,{ useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import PageIllustration2 from '../partials/PageIllustration2';
import Footer from '../partials/Footer';
  
function Contact() {
  const loader = <div>
    <div style={{
      animation: "400ms linear infinite anim-rotate",
      borderBottom: "2px solid transparent",
      borderLeft: "2px solid #fff",
      borderRadius: "50%",
      borderRight: "2px solid transparent",
      borderTop: "2px solid #fff",
      boxSizing: "border-box",
      height: 20,
      width: 20,
      marginLeft: 5,
      marginRight: 5,
      display: "block",
      }}
    />
  </div>
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true)
    emailjs.sendForm('service_fyz2u1v', 'template_5qr8frj', form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
         //empty fields
          var i=0
          var x=document.getElementsByTagName("input")
            for(i = 0; i<=x.length-1; i++){
              if(x.item(i).type!=="button") x.item(i).value = ""
            }
           document.getElementById('message').value=''
           //showAlert
           var showAlert=document.getElementById('alertSuccess')
           showAlert.className="py-6"
           setIsLoading(false)
      }, (error) => {
        var showAlert=document.getElementById('alertFail')
        showAlert.className="py-6"
        setIsLoading(false)
      });
  };
  if(!window.location.hash) {
    window.location = window.location + '#CPI';
    window.location.reload();
  }
  return (
    
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
     
      {/*  Page content */}
      <main >
        <section className="relative ">
          <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
            <PageIllustration />
            <PageIllustration2 />
          </div>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="lg:flex lg:justify-between" data-sticky-container>
              {/* Page header */}
                <aside className="relative hidden lg:block w-64 mr-20 mt-20 shrink-0 ">
                  <div className='border-gray-600 dark:border-gray-400 border-2 rounded-lg pl-6 py-6'data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>
                    <h4 className="text-xl font-bold text-rose-700 dark:text-rose-600 leading-snug tracking-tight mb-4">Nos coordonnées</h4>
                    <ul className="font-medium list-disc px-4 text-gray-600 dark:text-gray-400">
                      <li className="py-1 ">Tel : +33 1 86 57 00 00
                      </li>
                      <li className="py-1 ">Espace Claude Monet <br/> 11 rue Maurice Leblanc<br/>78290 Croissy sur seine
                      </li>
                    </ul>
                  </div>
                </aside>
              {/* Contact form */}
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                <form ref={form} className="max-w-xl mx-auto" onSubmit={sendEmail} method='POST'>
                  <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                    <h1 className="h1 font-libre mb-4">Contactez-nous</h1> 
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-5">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                      <label className="block text-gray-600 dark:text-gray-400 text-sm font-medium mb-1" htmlFor="first-name">Prénom <span className="text-red-600">*</span></label>
                      <input id="first-name" type="text" className="form-input w-full" name='first-name' required />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block text-gray-600 dark:text-gray-400 text-sm font-medium mb-1" htmlFor="last-name">Nom <span className="text-red-600">*</span></label>
                      <input id="last-name" type="text" className="form-input w-full" name='last-name' required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-5">
                    <div className="w-full px-3">
                      <label className="block text-gray-600 dark:text-gray-400 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                      <input id="company" type="email" className="form-input w-full" name='email' required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-5">
                    <div className="w-full px-3">
                      <label className="block text-gray-600 dark:text-gray-400 text-sm font-medium mb-1" htmlFor="role">Fonction <span className="text-red-600">*</span></label>
                      <input id="company" type="text" className="form-input w-full" name='role' required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-5">
                    <div className="w-full px-3">
                      <label className="block text-gray-600 dark:text-gray-400 text-sm font-medium mb-1" htmlFor="company">Entreprise <span className="text-red-600">*</span></label>
                      <input id="company" type="text" className="form-input w-full" name='company' required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-5">
                    <div className="w-full px-3">
                      <label className="block text-gray-600 dark:text-gray-400 text-sm font-medium mb-1" htmlFor="phone">Numéro de téléphone <span className="text-red-600">*</span></label>
                      <input id="phone" type="tel" className="form-input w-full" name='phone' required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-5">
                    <div className="w-full px-3">
                      <div className="flex justify-between items-center mb-1">
                        <label className="block text-gray-600 dark:text-gray-400 text-sm font-medium" htmlFor="message">Message</label>
                      
                      </div>
                      <textarea id="message" rows="4" className="form-textarea w-full" name='message' required></textarea>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">   
                    <div className="w-full px-3">
                      <div className="g-recaptcha" data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}></div>
                    </div>
                  </div>                
                  <div className="flex flex-wrap -mx-3 mt-6">   
                    <div className="w-full px-3">
                      <button className="btn text-white bg-rose-700 hover:bg-rose-600 w-full flex items-center" >
                      {isLoading ? loader : (<span>Envoyer</span>)}
                        <svg className="w-3 h-3 shrink-0 mt-px ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {/* Submit Alert */}
              <div  id='alertSuccess' className='hidden'> 
                <div className="bg-blue-100 border-y border-blue-500 text-blue-700 px-4 py-4  dark:border-x dark:rounded-lg dark:bg-transparent dark:border-gray-400 dark:text-gray-400" role="alert">
                  <div className="grid gap-4 grid-cols-12 justify-items-center">
                    <div className="py-1 col-span-1 justify-items-center"><svg className="fill-current h-6 w-6 dark:text-gray-400 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div className='col-span-10'>
                      <p className="text-lg font-bold">Message réçu avec succès</p>
                     
                    </div>
                    <a className="col-span-1 justify-items-center" href="/">
                      <svg className="fill-current h-6 w-6 text-blue-500 dark:text-gray-400 hover:text-rose-700" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <title>Close</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div  id='alertFail' className='hidden'> 
                <div className="bg-rose-400 border-y border-rose-600 text-rose-700 px-4 py-4  dark:border-x dark:rounded-lg dark:bg-transparent dark:border-gray-400 dark:text-gray-400" role="alert">
                  <div className="grid gap-4 grid-cols-12 justify-items-center">
                    <div className="py-1 col-span-1 justify-items-center"><svg className="fill-current h-6 w-6 dark:text-gray-400 text-rose-600 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div className='col-span-10'>
                      <p className="text-lg font-bold">Un problème est survenu lors du l'envoi de message. Renvoyer votre message !</p>
                    </div>
                  </div>
                </div>
              </div>

              </div>
            </div>
        </section>
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Contact;