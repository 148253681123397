import React from 'react';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import PageIllustration2 from '../partials/PageIllustration2';

import Footer from '../partials/Footer';

function MdpDuJour(){
  let mdp = "";
  let key = "pgbdjsuerejeovbghsjzdbgefvcsbkodjzdwkjdkmlmolmsgzhgslmxnbsgdscphdgqdfcsgdyhbncjkslqgetxwxlqjjdeocxkqkxbndhehncnndfkslkdhtgdfbcncqkkdndkcokdksmqsqk";
  let today = new Date();
  
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  let myDate = dd + '/' + mm + '/' + yyyy;

  var x,y,j;
  x=myDate.substr(0,2);
  for(var i=0;i<10;i++){
      if(i!==2 && i!==5){
          y=myDate.substr(i,1);
          j= parseFloat(i) + parseFloat(x) + parseFloat(y);
          mdp= mdp+key.substr(j,1);
      }
  }
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
     
      {/*  Page content */}
      <main >
        <section className="relative ">
          <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
            <PageIllustration />
            <PageIllustration2 />
          </div>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-xl mx-auto">
                  <div className="max-w-3xl mx-auto text-center pb-12 ">
                      <h1 className="h1 font-libre mb-8 py-8">Mot de passe du jour</h1> 
                      <p id="mdp" className="border-gray-600 dark:border-gray-400 border-2 rounded-lg text-gray-600 dark:text-gray-400 h3 py-12 mt-8 mb-4">{mdp.toUpperCase()}</p>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}
export default MdpDuJour;