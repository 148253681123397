import React from 'react';


function CubicusComponent() {
    return (
      <section>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20">
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h3 mb-8 dark:text-gray-300 font-libre" data-aos="fade-left" data-aos-anchor="[data-aos-id-tabs]">Gérez tous vos flux bancaires et de trésorerie avec la solution la plus complète du marché.</h2>
              <p className=" text-xl text-gray-600 dark:text-gray-400" data-aos="fade-left" data-aos-anchor="[data-aos-id-tabs]">CUBICUS est une solution modulaire alliant une sécurisation inégalée de vos échanges et une simplicité d'utilisation appréciée.<br/> Ses modules Paiements, Trésorerie, Chèques, Rapprochement, Export comptable et l'application mobile CUBICUS peuvent être acquis ensemble ou séparément, en mode licence ou en mode hébergé.</p>
            </div>
            {/* Features */}
            <div className="grid gap-20">
              {/* 1st Feature */}
              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                <div className=" max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-left" data-aos-duration="1500">
                  <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/Cubicus/1.jpg').default} width="400" alt='Cubicus 1' />
                </div>
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-right" data-aos-duration="1500"> 
                  <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <h3 className="h3 dark:text-gray-300 font-libre mb-4">CUBICUS paiements</h3>
                    <h4 className='h4 dark:text-gray-300 font-libre mb-4 '>Montez en puissance et en sécurité sur la gestion de vos paiements</h4>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                        <li>Simplifiez vos paiements et encaissements.</li>
                        <li>Sécurisez vos règlements grâce au parapheur électronique.</li>
                        <li>Transférez facilement vos fichiers vers vos banques.</li>
                        <li>Formatez vos flux aux normes bancaires.</li>
                        <li>Gagnez en visibilité avec des reportings clairs et complets.</li>     
                    </ul>
                  </div>
                </div>
              </div>
              {/* 2nd Feature*/}
              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                {/* Image */}
                <div className="items-left max-w-xl  md:w-full mx-0 md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-left" data-aos-duration="1500">
                  <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/Cubicus/2.jpg').default}width="400" alt='Cubicus 2' />
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-left" data-aos-duration="1500">
                  <div className="md:pl-4 lg:pl-12 xl:pl-16">
                    <h3 className="h3 dark:text-gray-300 font-libre mb-4">CUBICUS trésorerie</h3>
                    <h4 className='h4 dark:text-gray-300 font-libre mb-4'>Gérez et pilotez votre trésorerie comme jamais auparavant</h4>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                        <li>Gérez tous vos relevés de comptes dans un même espace.</li>
                        <li>Pilotez finement vos prévisions.</li>
                        <li>Rapprochez votre prévisionnel avec vos relevés bancaires.</li>
                        <li>Visualisez en un coup d'œil les fiches en valeur.</li>
                        <li>Gérez votre cash-pooling et vos comptes courants.</li>
                        <li>Pilotez sereinement vos placements et emprunts.</li>
                        <li>Contrôlez les échelles d'intérêts.</li>   
                    </ul>
                  </div>
                </div>
              </div>
              {/* 3rd Feature */}
              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                {/* Image */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-left" data-aos-duration="1500">
                  <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/Cubicus/3.jpg').default}width="400" alt='Cubicus 3' />
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-right" data-aos-duration="1500">
                  <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <h3 className="h3 dark:text-gray-300 font-libre mb-4 ">CUBICUS chèques</h3>
                    <h4 className='h4 dark:text-gray-300 font-libre mb-4 '>Éditez vos chèques en toute sécurité</h4>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                        <li>Saisissez et intégrez vos lots de chèques à imprimer.</li>
                        <li>imprimez facilement tous vos flux matérialisés : chèques, BOR</li>
                        <li>Ne tolérez aucun compromis sur la sécurité.</li>
                    </ul>
                  </div>  
                </div>
              </div>

              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                {/* Image */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-left" data-aos-duration="1500">
                  <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/Cubicus/4.jpg').default}width="400" alt='Cubicus 4' />
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-left" data-aos-duration="1500">
                  <div className="md:pl-4 lg:pl-12 xl:pl-16">
                    <h3 className="h3 dark:text-gray-300 font-libre mb-4">CUBICUS export comptable </h3>
                    <h4 className='h4 dark:text-gray-300 font-libre mb-4'>Générez automatiquement vos écritures comptables</h4>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                        <li>Exportez instantanément vos flux de trésorerie dans votre comptabilité.</li>
                        <li>Exportez automatiquement vos mouvements bancaires dans votre comptabilité.</li>
                        <li>Gardez le contrôle !</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                {/* Image */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-left" data-aos-duration="1500">
                  <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/Cubicus/5.jpg').default} width="400" alt='Cubicus 5' />
                </div>
                {/* Content */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-right" data-aos-duration="1500">
                  <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <h3 className="h3 dark:text-gray-300 font-libre mb-4">CUBICUS rapprochement comptable</h3>
                    <h4 className='h4 dark:text-gray-300 font-libre mb-4'>Gagnez en efficacité dans vos rapprochements comptables</h4>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                        <li>Rapprochez vos écritures comptables et vos mouvements bancaires en un clin d'œil.</li>
                        <li>Bénéficiez de reportings complets.</li>
                        <li>Conservez un historique de toutes vos clôtures.</li>
                    </ul>
                  </div>  
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
    );
  }
  export default CubicusComponent;
  