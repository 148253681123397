import React from 'react';

function ProcessOc() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800" >

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20" data-aos="fade-up" data-aos-duration="1000">
            <h3 className="h3 font-libre mb-4"> OpenCash mobile </h3>
            <h4 className='h4 font-libre mb-4'>Accédez à la majorité des fonctionnalités d’OpenCash depuis votre smartphone partout dans le monde</h4>
          </div>
          {/* Glow illustration */}
          <svg className="absolute left-1/2 transform -translate-x-1/2 mt-20 lg:mt-40 pointer-events-none -z-1 dark:opacity-20 hidden md:block" aria-hidden="true" width="854" height="509" viewBox="0 0 854 509" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="iphonesill__a">
                <stop stopColor="#D4D4D8" offset="0%" />
                <stop stopColor="#D4D4D8" stopOpacity="0" offset="100%" />
              </radialGradient>
              <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="iphonesill__b">
                <stop stopColor="#be123c" offset="0%" />
                <stop stopColor="#be123c" stopOpacity="0" offset="100%" />
              </radialGradient>
            </defs>
            <g transform="translate(-64 -64)" fill="none" fillRule="evenodd">
              <circle fillOpacity=".64" fill="url(#iphonesill__a)" cx="300" cy="300" r="300" />
              <circle fillOpacity=".72" fill="url(#iphonesill__b)" cx="729" cy="384" r="240" />
            </g>
          </svg>

          {/* Items */}
          <div className="mt-12 max-w-sm mx-auto grid gap-12 md:gap-x-6 lg:gap-x-12 md:grid-cols-3 items-start md:max-w-none" data-aos="fade-up" data-aos-duration="1000">
            {/* 1st item */}
            <div className="relative flex flex-col items-center">
              
              {/* Mobile mockup */}
              <div className="inline-flex relative justify-center items-center">
                {/* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) */}
                <img className="absolute" src={require('../images/OpenCash/phone.jpg').default} width="290" height="624" style={{ maxWidth: '84.33%' }} alt="App screen 02" />
                {/* iPhone mockup */}
                <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={require('../images/iphone-mockup.png').default} width="344" height="674" alt="iPhone mockup" aria-hidden="true" />
              </div>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center">
              {/* Mobile mockup */}
              <div className="inline-flex relative justify-center items-center">
                {/* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) */}
                <img className="absolute" src={require('../images/OpenCash/phone2.jpg').default} width="290" height="624" style={{ maxWidth: '84.33%' }} alt="App screen 02" />
                {/* iPhone mockup */}
                <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={require('../images/iphone-mockup.png').default} width="344" height="674" alt="iPhone mockup" aria-hidden="true" />
              </div>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center">
              {/* Mobile mockup */}
              <div className="inline-flex relative justify-center items-center">
                {/* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) */}
                <img className="absolute" src={require('../images/OpenCash/phone3.jpg').default} width="290" height="624" style={{ maxWidth: '84.33%' }} alt="App screen 03" />
                {/* iPhone mockup */}
                <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={require('../images/iphone-mockup.png').default} width="344" height="674" alt="iPhone mockup" aria-hidden="true" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default ProcessOc;
