import React from 'react';

import Header from '../partials/Header';


import Footer from '../partials/Footer';
import HeroHepatus from '../partials/HeroHepatus';
import IntroHepatus from '../partials/IntroHepatus';
import Cta from '../partials/Cta';

function Hepatus() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">

        {/*  Page sections */}
        <HeroHepatus />
        <IntroHepatus />
        <Cta />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Hepatus;