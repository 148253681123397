import React from 'react';

function IntroHepatus() {
  return (
    <section>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Line */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
             <h2 className="h3 mb-8 dark:text-gray-300 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Se doter d’une gestion rationalisée des pouvoirs est aujourd’hui une nécessité.</h2>
              <p className=" text-xl text-gray-600 dark:text-gray-400" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Hepatus est une solution hébergée ou On-premise à destination des grandes entreprises pour gérer les pouvoirs bancaires. <br></br><br></br>Pionnier des solutions logicielles de gestion de pouvoirs, HEPATUS a su conquérir les Directions Administratives, Financières et les équipes juridiques des plus grandes entreprises, fédérations et associations.</p>
          </div>
          {/* Features */}
          <div className="pt-8 max-w-xl mx-auto md:max-w-none space-y-20">

            {/* 1st Feature */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-2">
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Visualisez instantanément l'ensemble de vos pouvoirs :</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                  <li>Consultez les signataires d'un compte bancaire.</li>
                   <li>Visualisez les pouvoirs d'un signataire.</li>
                   <li>Conservez l'historique de vos pouvoirs.</li>
                   <li>Tracez les auteurs et les dates des modifications.</li>   
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none -z-10" aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600" src={require('../images/Hepatus/1.jpg').default} width="350" alt='Img 1' />
                </div>
              </div>
            </div>
            {/* 2nd Feature*/}
            <div className="flex flex-col-reverse md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-2 mt-6">  
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h2 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Gérez vos pouvoirs en toute simplicité :</h2>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                    <li>Hepatus vous met à disposition des wizards pour faciliter les principales modifications de vos pouvoirs.</li>
                    <li>Stockez tous vos documents signés dans la GED intégrée d’Hepatus.</li>
                    <li>Intégrez et indexez automatiquement vos documents dans la GED.</li>
                    <li>Suivez et commentez vos dossiers.</li>   
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none-z-10 " aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600"  src={require('../images/Hepatus/2.jpg').default}width="350" alt='Img 2'  />
                </div>
              </div>
            </div>
            {/* 3rd Feature */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-2">
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Communiquez en temps réel avec vos banquiers :</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                    <li>Éditez vos courriers de mise à jour de pouvoirs facilement.</li>
                    <li>Bénéficiez d’une productivité record en laissant Hepatus gérer vos papiers en-tête.</li>
                    <li>Osez passer à la dématérialisation de vos pouvoirs en optant pour la norme e-BAM.</li>
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none -z-10" aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600" src={require('../images/Hepatus/3.jpg').default} width="350" alt='Img 3'  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default IntroHepatus;
