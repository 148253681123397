import React from 'react';

function ExodonComponent() {
  return (
    <section>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Line */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
             <h3 className="h3 mb-8 dark:text-gray-300 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Retrouvez tout l'ADN de CPI dans une solution full-web pour les structures à taille humaine.</h3>
              <p className=" text-xl text-gray-600 dark:text-gray-400" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Simple d'utilisation et entièrement sécurisée, EXODON est une plateforme internet de communication bancaire pour vos envois et réceptions de fichiers bancaires. Mais c'est aussi un service SEPAmail innovant pour faciliter les règlements de vos clients.</p>
          </div>

          {/* Features */}
          <div className="pt-8 max-w-xl mx-auto md:max-w-none space-y-20">

            {/* 1st Feature */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-2">
              
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Simplifiez vos échanges bancaires :</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                   <li>Récupération de vos relevés.</li>
                   <li>Réception et impression de vos fichiers bancaires.</li>
                   <li>Téléchargement des fichiers.</li>
                   <li>Envoi de vos remises de règlement avec signature numérique.</li>
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none -z-10" aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600" src={require('../images/Exodon/1.jpg').default} width="350" alt='Img 1' />
                </div>
              </div>
            </div>
            {/* 2nd Feature*/}
            <div className="flex flex-col-reverse md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-2 mt-6">  
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Facilitez les conditions de règlement de vos clients : </h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                    <li>Envoi des demandes de règlement.</li>
                    <li>Bénéficiez d'un interfaçage direct et sécurisé.</li>
                    <li>Vérifiez la fiabilité des coordonnées bancaires.</li>
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none-z-10 " aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600"  src={require('../images/Exodon/2.jpg').default} width="350" alt='Img 2' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ExodonComponent;
