import React from 'react';

function BlogSingleSisId() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">CPI et Sis ID unissent leurs forces pour lutter contre la fraude au virement bancaire</h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <h3 className="mb-4 h4 dark:text-gray-300 "> La fraude au virement bancaire, un fléau des entreprises</h3>
                <p className="mb-8 text-gray-600 dark:text-gray-400 ">Les fraudes bancaires sont de plus en plus violentes et perfectionnées. Suite à la crise sanitaire, les entreprises ont dû revoir leur organisation, laissant parfois des failles dans leurs systèmes d’informations. Celles-ci les rendent plus vulnérables face à la fraude au virement bancaire.</p>
                <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> CPI et Sis ID s'allient face à la fraude au virement bancaire</h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Éditeur de logiciels de gestion de trésorerie, accompagnant les entreprises dans la sécurisation de leurs transactions de paiement CPI et Sis ID font face à la fraude.</p>
                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700 " width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'> Le cœur de métier de CPI est basé sur la gestion des flux de paiements entre les entreprises et leurs partenaires bancaires à travers ses logiciels. Nos clients, à ce titre, sont de plus en plus sensibilisés à la fraude aux virements bancaires et en recherche de solutions intégrées à leurs solutions de trésorerie.  </p>
                        
                        <svg className=" float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Jocelyn Gaudeul - Responsable commercial de CPI Software</p>
                    </div>
                </div>
                <figure className="py-8">
                  <img className="w-full " src={require('../images/partenariat.jpg').default}  alt="News inner" />
                </figure>
               
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">
                Les équipes de CPI et  Sis ID ont collaboré pour développer un connecteur au sein du logiciel financier CUBICUS intégrant  la solution de vérification des coordonnées bancaires Sis Inside.
                </p>
                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'> Avec l’intégration de Sis Inside à CUBICUS, notre objectif est de simplifier le quotidien de nos utilisateurs tout en leur garantissant la sécurité la plus optimale. Parce qu’ensemble, nous sommes plus forts, le partenariat entre nos deux entreprises est une brique supplémentaire apportée à la lutte contre la fraude.</p>
                        
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Laurent Sarrat - CEO de Sis ID</p>
                    </div>
                </div>
                
               

                <p className="mb-4 text-gray-600 dark:text-gray-400 pt-8">
                En offrant une solution adaptée et collaborative, CPI et Sis ID vous permettent de centraliser vos outils en renforçant la sécurité.
                </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400">
                Ce partenariat avec Sis ID est une vraie valeur ajoutée. Sécurité optimale, performance, gain de temps et fluidité au quotidien en font un véritable allié dans la lutte contre la fraude aux virements bancaires.
                </p>
                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>C’est tout naturellement que nous nous sommes rapprochés de Sis ID, persuadés comme eux que le collectif est la meilleure réponse anti-fraude. Nos valeurs sont très proches, plaçant l’humain au cœur du dispositif. Ainsi, CPI bénéficie des fonctionnalités de vérification des coordonnées bancaires Sis ID désormais disponibles nativement au sein de CUBICUS (prochainement OpenCash), plateforme modulable de communication bancaire et de trésorerie multi-protocoles. </p>
                        
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Jocelyn Gaudeul - Responsable commercial de CPI Software</p>
                    </div>
                </div>
                <p className="mb-4 text-gray-600 dark:text-gray-400 pt-8">Éditeur des logiciels financiers depuis plus de 30 ans, CPI facilite et sécurise les échanges entre les entreprises et leurs partenaires banquiers.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Grâce à un contact permanent avec ses utilisateurs, CPI a pu affiner ces outils de gestion.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Constamment, à l'écoute de ses clients, CPI a fait le choix d'élargir sa gamme de logiciels pour rester au plus près de leurs besoins en termes de :</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>Gestion des flux bancaires et de trésorerie : CUBICUS, OpenCash.</li>
                  <li>Gestion des pouvoirs bancaires : HEPATUS.</li>
                  <li>Gestion des remises de chèques à l'encaissement : ADIORYX.</li>
                  <li>Gestion des mandats SEPA : CILIARIS.</li>
                  <li>Gestion des transferts de flux bancaires : EXODON.</li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleSisId;