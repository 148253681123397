import React from 'react';

function BlogSingleISO() {

    return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">La migration vers la norme ISO 20022 </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <h4 className="mb-4 h5 dark:text-gray-300 pt-8">Comment les départements financiers peuvent saisir l’opportunité et surmonter les défis ? </h4>
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/iso.jpg').default}  alt="News inner" />
                </figure>

                {/* <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Vous connaissez sans doute la « fraude au président », mais connaissez-vous celle liée au changement de RIB ? </h3> */}

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Que veut-on dire par la norme ISO 20022 ? </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La norme ISO 20022 est une norme internationale qui définit un langage commun pour la <strong>structuration</strong> des messages électroniques liés aux transactions financières. Elle vise à <strong>harmoniser</strong> les formats de ces messages en XML (Extensible Markup Language), afin de faciliter leur échange entre différentes entités financières, telles que les banques et les entreprises.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Qui est concerné par la migration vers la norme ISO 20022 ? </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La migration vers la norme <strong>ISO 20022</strong> implique principalement les banques, les systèmes interbancaires, et prochainement, les entreprises. Ce projet, lancé il y a une dizaine d’années, permet de rationaliser et d’harmoniser les standards de messages pour faciliter une <strong>intégration globale</strong>. Cette évolution remplacera progressivement les formats traditionnels d’ici novembre 2025.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Cette migration a déjà commencé avec le Crédit Suisse et la Banque Nationale Suisse.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Quelles étapes les entreprises devront-elles suivre lors de la migration vers la norme ISO 20022 ? </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La migration vers la norme <strong>ISO 20022</strong> est un projet important pour les entreprises. Elle nécessite une approche méthodique et une coordination étroite avec les banques. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La première étape consiste à évaluer la <strong>conformité</strong> de l'entreprise à la norme. Cela implique de communiquer avec les banques pour comprendre les nouveaux formats requis. Il est également important d'évaluer les outils de gestion de trésorerie existants pour s'assurer qu'ils peuvent intégrer les nouveaux standards ISO 20022.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La deuxième étape consiste à former et à sensibiliser les trésoriers et les équipes financières à la nouvelle norme. Cela permettra de rendre la transition beaucoup plus fluide. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Enfin, les entreprises doivent mettre à jour leurs systèmes <strong>ERP/TMS</strong> pour les adapter aux nouveaux types de données et informations sachant que ces données sont plus détaillées et structurées, donc plus compliquées à analyser que les anciennes.</p>


                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Quels impacts concrets de l’adoption de la norme ISO 20022 sur les entreprises ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La migration des entreprises vers la norme <strong>ISO 20022</strong> entraînera des changements significatifs touchants divers aspects de leurs opérations financières, son adoption s’inscrit dans une démarche visant à renforcer la conformité des entreprises aux normes internationales.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Après migration, les entreprises, et grâce à la structure plus riche de l’ISO 20022, pourront véhiculer un volume d’informations beaucoup plus important concernant les transactions, <strong>simplifiant ainsi les processus internes</strong>.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">De plus, avoir des données plus structurées va permettre certainement d’améliorer le traitement automatisé, ce qui contribue à une gestion plus efficace des flux et à une réduction des marges d’erreur. Sur le plan stratégique, les trésoriers d’entreprises vont pouvoir fournir des rapports plus détaillés, renforçant ainsi leur rôle en tant qu’acteurs clé au sein des entreprises.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La norme ISO 20022 s’inscrit dans une démarche de conformité aux réglementations financières en renforçant la normalisation, la transparence et la traçabilité des paiements et transactions. À travers la structure des adresses par <strong>balises</strong>.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Prenons l’exemple en comparant les éléments de données d'un paiement fictif suivant la norme MT avec ceux d'un paiement sous la norme ISO 20022.</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>
                    <><strong>Voici comment cela pourrait apparaître aujourd'hui dans la norme MT :</strong>
                    <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                      <p className='text-gray-600 dark:text-gray-400'>:56:/478169221 Burger Spot Russie</p>
                      <p className='text-gray-600 dark:text-gray-400'>16 Rue de la liberté</p>
                      <p className='text-gray-600 dark:text-gray-400'>90004 Los Angeles</p>
                    </div>
                    </>
                  </li>
                  <li>
                    <><strong>Et voici le nom et l'adresse dans le format structuré de l'ISO 20022 :</strong>
                    <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                      <pre>
                          <strong>&lt;Cdtr&gt;</strong><br/>
                              <strong>&lt;Nm&gt;</strong>Burger Spot Russie<strong>&lt;/Nm&gt;</strong><br/>
                              <strong>&lt;PstlAdr&gt;</strong><br/>
                              <strong>&lt;StrtNm&gt;</strong>Liberty Street<strong>&lt;/StrtNm&gt;</strong><br/>
                              <strong>&lt;BldgNb&gt;</strong>16<strong>&lt;/BldgNb&gt;</strong><br/>
                              <strong>&lt;PstCd&gt;</strong>90004<strong>&lt;/PstCd&gt;</strong><br/>
                              <strong>&lt;TwnNm&gt;</strong>Los Angeles<strong>&lt;/TwnNm&gt;</strong><br/>
                              <strong>&lt;Ctry&gt;</strong>US<strong>&lt;/Ctry&gt;</strong><br/>
                              <strong>&lt;/PstlAdr&gt;</strong><br/>
                          <strong>&lt;/Cdtr&gt;</strong><br/>
                      </pre>
                    </div>
                    </>
                  </li>
                </ul>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Dans le premier cas, il est très probable que le paiement tombe dans un filtre qui déclenchera une enquête. En effet, le terme "Russie" apparaît dans le nom et l'adresse, et la destination du paiement pourrait causer des confusions.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Dans l'exemple ISO 20022, l'adresse postale « PstlAdr » identifie sans ambiguïté le pays "États-Unis" à l'aide d'un code pays standard à deux caractères « US ».</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Il est également clair que le terme « Russie » fait partie du nom « Nm » de l'entreprise, et non de l’adresse.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le paiement peut être ainsi traité sans problème, et la banque pourrait économiser en argent et en temps, le traitement et la correction manuels.</p>
                
                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Quels sont les défis potentiels auxquels les trésoriers et les départements financiers peuvent être confrontés pendant la transition vers la norme ISO 20022 ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La transition vers la norme ISO 20022 peut être techniquement complexe en raison des structures plus détaillées. Les trésoriers d’entreprises devront s’assurer que leurs <strong>TMS</strong> sont capables de traiter ce nouveau type de données de manière efficace, ainsi que de gérer la transition cruciale au sein de leurs équipes pour s’adapter aux nouveaux formats et processus, à travers des formations adéquates pour garantir une utilisation efficace et minimiser les erreurs potentielles.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Cette migration implique également une coordination étroite entre les <strong>entreprises</strong> et les <strong>banques</strong>, pour assurer une communication fluide et une compréhension mutuelle des changements pour éviter tout dysfonctionnement dans les flux bancaires.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La migration vers la norme <strong>ISO 20022</strong> peut aussi prendre plusieurs mois ou années. Cela peut être un obstacle pour les entreprises qui ne disposent pas d'un budget ou d’un temps suffisant.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Sources</h4>
                <a className= "text-rose-700" href="https://www.francepaymentsforum.eu/fr/enjeux-migration-so-20022/" target="_blank" rel="noopener noreferrer">Les enjeux de la migration à ISO 20022 - FRANCE PAYMENTS FORUM</a><br></br>
                <a className= "text-rose-700" href="https://www.swift.com/standards/iso-20022" target="_blank" rel="noopener noreferrer">About ISO 20022 | Swift</a>

              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleISO;
