import React, { useState, useRef, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Dropdown from '../utils/Dropdown';
import Transition from '../utils/Transition.js';


function Header() {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // Handle light modes
  const [darkMode, setDarkMode] = useState(localStorage.getItem('dark-mode') === null ? false : localStorage.getItem('dark-mode') === 'true' ? true : false);

  useEffect(() => {
    console.log(darkMode)
    console.log('type : ', typeof(darkMode))
    localStorage.setItem('dark-mode', darkMode.toString()); // Convert to string
    setDarkMode(darkMode);
    if (darkMode === true) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]); 

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">

          {/* Site branding */}
          <div className="mt-3 mr-2">
            {/* Logo */}
			      <Link to="/" className="block" aria-label="CPI">
              <img src={require('../images/logo.png').default} width="125" alt="CPI logo" />
			      </Link>
          </div>
          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow opacity-90">
            {/* Desktop menu links */}
            
            <ul className="flex grow flex-wrap items-center font-medium">
            
              <Dropdown title="Solutions & Services">
                {/* 2nd level: hover */}
                <li>
                  <Link to="/opencash" className="text-lg text-gray-700 dark:text-gray-400 hover:text-rose-700 dark:hover:text-rose-600 flex py-2 px-4 leading-tight">OpenCash</Link>
                </li>
                <li>
                  <Link to="/cubicus" className="text-lg text-gray-600 dark:text-gray-400 hover:text-rose-700 dark:hover:text-rose-600 flex py-2 px-4 leading-tight">Cubicus</Link>
                </li>
				        <li>
                  <Link to="/hepatus" className="text-lg text-gray-600 dark:text-gray-400 hover:text-rose-700 dark:hover:text-rose-600 flex py-2 px-4 leading-tight">Hepatus</Link>
                </li>
				        <li>
                  <Link to="/ciliaris" className="text-lg text-gray-600 dark:text-gray-400 hover:text-rose-700 dark:hover:text-rose-600 flex py-2 px-4 leading-tight">Ciliaris</Link>
                </li>
				        <li>
                  <Link to="/exodon" className="text-lg text-gray-600 dark:text-gray-400 hover:text-rose-700 dark:hover:text-rose-600 flex py-2 px-4 leading-tight">Exodon</Link>
                </li>
                <li>
                  <Link to="/adioryx" className="text-lg text-gray-600 dark:text-gray-400 hover:text-rose-700 dark:hover:text-rose-600 flex py-2 px-4 leading-tight">Adioryx</Link>
                </li>
              </Dropdown>
              <li>
                <Link to="/societe" className="text-xl text-boldtext-gray-900 hover:text-rose-700 dark:text-white dark:hover:text-rose-600 px-5 py-2 flex items-center transition duration-150 ease-in-out">Société</Link>
              </li>
              
              <li>
                <a href="/#temoignages" className="text-xl text-boldtext-gray-900 hover:text-rose-700 dark:text-white dark:hover:text-rose-600 px-5 py-2 flex items-center transition duration-150 ease-in-out"> Témoignages</a>
              </li>
              {/* 1st level: hover */}
              <li>
                <Link to="/blog" className="text-xl  text-gray-900 hover:text-rose-700 dark:text-white  dark:hover:text-rose-600 px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</Link>
			        </li>
			        <li>
                <Link to="/contact" className="text-xl text-boldtext-gray-900 hover:text-rose-700 dark:text-white dark:hover:text-rose-600 px-5 py-2 flex items-center transition duration-150 ease-in-out">Contact</Link>
               </li>
            </ul>
            {/* Desktop lights switch */}
            <div className="form-switch flex flex-col justify-center ml-3">
              <input type="checkbox" name="light-switch" id="light-switch-desktop" className="light-switch sr-only" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
              <label className="relative" htmlFor="light-switch-desktop">
                <span className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10" aria-hidden="true"></span>
                <svg className="absolute inset-0" width="44" height="24" viewBox="0 0 44 24" xmlns="http://www.w3.org/2000/svg">
                  <g className="fill-current text-white" fillRule="nonzero" opacity=".88">
                    <path d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z" />
                    <circle cx="32" cy="12" r="3" />
                    <circle fillOpacity=".4" cx="12" cy="12" r="6" />
                    <circle fillOpacity=".88" cx="12" cy="12" r="3" />
                  </g>
                </svg>
                <span className="sr-only">Switch to light / dark version</span>
              </label>
            </div>            

          </nav>

          {/* Mobile menu */}
          <div className="inline-flex md:hidden">

            {/* Mobile lights switch */}
            <div className="form-switch flex flex-col justify-center mr-6 -mt-0.5">
              <input type="checkbox" name="light-switch" id="light-switch-mobile" className="light-switch sr-only" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
              <label className="relative" htmlFor="light-switch-mobile">
                <span className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10" aria-hidden="true"></span>
                <svg className="absolute inset-0" width="44" height="24" viewBox="0 0 44 24" xmlns="http://www.w3.org/2000/svg">
                  <g className="fill-current text-white" fillRule="nonzero" opacity=".88">
                    <path d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z" />
                    <circle cx="32" cy="12" r="3" />
                    <circle fillOpacity=".4" cx="12" cy="12" r="6" />
                    <circle fillOpacity=".88" cx="12" cy="12" r="3" />   
                  </g>
                </svg>
                <span className="sr-only">Switch to light / dark version</span>
              </label>
            </div>

            {/* Hamburger button */}
            <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"              
            >
              <nav id="mobile-nav" ref={mobileNav} className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar">
                <div className="py-6 pr-4 pl-20">                  
                  {/* Logo */}
                  <Link to="/" className="block" aria-label="CPI">
                    <img src={require('../images/logo.png').default} width="125" alt="CPI logo mobile"/>
			            </Link>
                  {/* Links */}
                  <ul>
                    <li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                      <a className="flex text-lg text-gray-600 dark:text-gray-400 py-2" href="/#logiciels">Solutions & Services</a>
                      <ul className="pl-4 text-lg">
                        <li>
                          <Link to="/opencash" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">OpenCash</Link>
                        </li>
                        <li>
                          <Link to="/cubicus" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Cubicus</Link>
                        </li>
						            <li>
                          <Link to="/hepatus" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Hepatus</Link>
                        </li>
					            	<li>
                          <Link to="/ciliaris" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Ciliaris</Link>
                        </li>
						            <li>
                          <Link to="/exodon" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Exodon</Link>
                        </li>
                        <li>
                          <Link to="/adioryx" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Adioryx</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/societe" className="flex text-lg text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Société</Link>
                    </li>
                    <li>
                      <a href="/#temoignages" className="flex text-lg text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"> Témoignages</a>
                    </li>
					          <li>
                      <Link to="/blog" className="flex text-lg text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact" className="flex text-lg text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Contact</Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </Transition>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
