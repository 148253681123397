import React from 'react';
import { CountUp } from 'use-count-up'



function Stats() {
  return (
    <section className="relative">
      {/* Background gradient (light version only) */}
      <div className="absolute bottom-0 left-0 right-0 h-128 bg-gradient-to-t from-white pointer-events-none -z-10 dark:hidden" aria-hidden="true"></div>
      {/* End background gradient (light version only) */}
      <div className="absolute inset-0 h-128 dark:opacity-25 pointer-events-none" aria-hidden="true"></div>
      <div className="md:py-12 dark:from-gray-900 dark:to-gray-900 pointer-events-none" aria-hidden="true">
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          
          <div className="grid grid-cols-2 gap-4 lg:gap-6 md:grid-cols-4 text-center opacity-80 mb-8" data-aos-id-stats>
            {/* 1st item */}
            <div className="rounded-lg bg-gray-300 dark:bg-gray-800 py-8 px-1 shadow-xl " data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]">
			        <div className="font-red-hat-display text-3xl font-extrabold tracking-tighter mb-1 ">
					      <CountUp className="font-red-hat-display text-3xl font-extrabold tracking-tighter mb-1" isCounting end={1100} duration={2} />+
			        </div>
		          <div className="text-gray-900 dark:text-gray-400">Clients</div>
            </div>
            {/* 2nd item */}
            <div className="rounded-lg bg-gray-300 dark:bg-gray-800 py-8 px-1 shadow-xl" data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]" data-aos-delay="100">
              <div className="font-red-hat-display text-3xl font-extrabold tracking-tighter mb-1 gradient">
				        <CountUp className="font-red-hat-display text-3xl font-extrabold tracking-tighter mb-1" isCounting end={10500} duration={2.5} />+
			        </div>
              <div className="text-gray-900 dark:text-gray-400">Utilisateurs</div>
            </div>
            {/* 3rd item */}
            <div className="rounded-lg bg-gray-300 dark:bg-gray-800 py-8 px-1 shadow-xl" data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]" data-aos-delay="200">
              <div className="font-red-hat-display text-3xl font-extrabold tracking-tighter mb-1">
				        <CountUp className="font-red-hat-display text-2xl font-extrabold tracking-tighter mb-1" isCounting end={750} duration={3} />K+
			        </div>
              <div className="text-gray-900 dark:text-gray-400">Mandats gérés</div>
            </div>
            {/* 4th item */}
            <div className="rounded-lg bg-gray-300 dark:bg-gray-800 py-8 px-1 shadow-xl" data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]" data-aos-delay="300">
              <div className="font-red-hat-display text-3xl font-extrabold tracking-tighter mb-1">1987</div>
              <div className="text-gray-900 dark:text-gray-400">Année de création</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Stats;