import React from 'react';

import HeaderHome from '../partials/HeaderHome';
import HeroHome from '../partials/HeroHome';
import Stats from '../partials/Stats';
import Tabs from '../partials/Tabs';
import TestimonialsCarousel from '../partials/TestimonialsCarousel';
import PartnerCircles from '../partials/PartnerCircles'; 
import News from '../partials/News';
import Footer from '../partials/Footer';
// import ModalFerie from '../partials/ModalFerie';

function Home() {
  return (
    <React.Fragment>   
      {/* Put this part before </body> tag */}
      
      <div className="flex flex-col min-h-screen overflow-hidden">
      
        {/*  Site header */}
        <HeaderHome />
        
        {/*  Page content */}
        
        <main className="grow">
        
          {/*  Page sections */}
          <HeroHome />
          <Stats />
          
          <Tabs />
          
          <TestimonialsCarousel />
          
          <PartnerCircles />
          <News />
        </main>

        {/*  Site footer */}
        <Footer />

      </div>
    </React.Fragment>    
  );
}

export default Home;
