import React from 'react';

function BlogSingleSwift() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down"> Universwiftnet – 9 juin 2022, Paris. </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <h3 className="mb-4 h4 dark:text-gray-300 ">Le Forum de la relation Banque-Entreprise et de ses technologies depuis 2004.</h3>
                {/* <p className="mb-8 text-gray-600 dark:text-gray-400 ">OpenCash est la première solution de TMS Full Web de CPI qui concentre l'ensemble de son expertise dans le domaine de la communication bancaire, les moyens de paiements, la trésorerie et les Pouvoirs bancaires pour les entreprises.</p> */}
                
                <figure className="py-8">
                  <img className="w-full " src={require('../images/swiftnet.jpg').default}  alt="News inner" />
                </figure>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Universwiftnet 2022 : cap vers la confiance</h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Chaque année, Universwiftnet, le forum dédié à la relation banque-entreprise et ses technologies, rassemble plus de 1200 participants au Palais Brongniart pour remonter les dernières tendances en matière de relation banque-entreprise.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">À l’occasion de cet événement, l'équipe de CPI a participé à l'Universwiftnet 2022, le forum de la relation banque-entreprise et de ses technologies depuis 2004, dont le thème de cette année était : 'Cap vers la confiance'.  </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">CPI a échangé lors d’un atelier témoignage sur : l’eBAM : Mise en production d’un projet multi-bancaire. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Nous avons présenté les éléments indispensables qui vous permettront de faire aboutir votre projet #eBAM, au travers d'un cas concret : Bouygues Immobilier avec la présence de Ludovic NICOLLE, Directeur Trésorerie et Financements Bancaires et Crédit Agricole CIB avec la présence de Qiaoda SPRAUER Global Product Expert for eBAM. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Pour plus d’informations, visitez le site  <a href='https://universwiftnet.evenements.agefi.fr/' target="_blank" rel="noopener noreferrer">Universwiftnet</a> (Cap vers la confiance)  </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleSwift;