import React from 'react';
import { Link } from 'react-router-dom';

function HeroHome() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-16 ">
          <img className="absolute inset-0 w-full h-full object-cover " src={require('../images/8.jpg').default} width="1440" height="577" alt='bg home'/>
          <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900" aria-hidden="true"></div>
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-12 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="h1 font-libre mb-4 " data-aos="fade-down">Sécurisez et simplifiez vos flux bancaires</h1>
                <p className = "p text-xl mb-4"> Reprenez le contrôle de votre gestion de trésorerie en quelques clics avec CPI, Éditeur de logiciels de gestion financière auprès des entreprises depuis plus de 30 ans.</p>
                <div className="mt-8" data-aos="fade-down" data-aos-delay="300">
                  <Link className="btn rounded-full text-white bg-rose-700 hover:bg-rose-600 ml-3" to="/societe">En savoir plus</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default HeroHome;