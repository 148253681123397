import React from 'react';

function BlogSingleKaufman() {

    return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">Comment Cubicus a renforcé l’efficacité opérationnelle de Kaufman & Broad ?</h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <h4 className="mb-4 h5 dark:text-gray-300 pt-8">La Flexibilité, la Facilité d’utilisation et un Engagement inégalé en lumière.</h4>
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/kaufman.jpg').default}  alt="News inner" />
                </figure>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8">Présentation de l’entreprise :</h3>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Kaufman & Broad, leader majeur de l'immobilier français au fil des années.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Forte de son statut de développeur-constructeur depuis plus de 50 ans, l’entreprise opère dans le secteur résidentiel proposant une gamme variée de projets, allant de maisons individuelles en village à des appartements, résidences de services, hôtels, et immobilier tertiaire. Kaufman & Broad contribue de manière significative à l’évolution des villes françaises, en privilégiant la personnalisation, la qualité architecturale et une approche centrée sur le client.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>Secteur d’activité</strong> : Immobilier</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 mb-8"><strong>Taille</strong> : 500/1000 Salariés </p>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>La relation avec CPI va au-delà d'une simple collaboration. C'est une connexion basée sur la compréhension mutuelle et une assistance continue. Chacun des modules de Cubicus joue un rôle crucial dans notre processus global, contribuant de manière significative à l'amélioration de nos opérations. Une polyvalence qui renforce notre confiance envers CPI.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Philippe MINIER, Directeur des Systèmes d’Information<br/>Kaufman & Broad </p>
                    </div>
                </div>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>La simplicité de prise en main et la flexibilité de Cubicus ont véritablement modernisé nos opérations financières, remplaçant avec succès une solution obsolète. Une transition essentielle pour notre efficacité opérationnelle.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Philippe MINIER, Directeur des Systèmes d’Information<br/>Kaufman & Broad </p>
                    </div>
                </div>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>Notre objectif d'automatiser le rapprochement bancaire est en cours, et la collaboration avec CPI pour explorer des technologies émergentes comme la RPA et l'IA souligne notre engagement commun pour l'innovation et l'optimisation continue.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>Philippe MINIER, Directeur des Systèmes d’Information<br/>Kaufman & Broad</p>
                    </div>
                </div>


                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Les apports de Cubicus à Kaufman & Broad :</h4>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Une facilité de prise en main, une flexibilité, une disponibilité, un engagement et une dynamique dans la gestion financière quotidienne.</li>
                  <li>Une connexion basée sur la compréhension mutuelle et une assistance continue.</li>
                </ul>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Pourquoi Kaufman & Broad a choisi Cubicus comme solution ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le choix stratégique s’est basé sur le coût total de possession (TCO), la couverture fonctionnelle adaptée aux besoins spécifiques et l’importance du critère relationnel avec l’équipe CPI.</p>



                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Satisfaction et Confiance</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La relation entre Kaufman & Broad et CPI a évolué au-delà d’une simple collaboration professionnelle pour devenir un partenariat solide et durable, fondé sur la confiance, la disponibilité et l’engagement.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Modernisation des opérations et personnalisation totale</h4>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Cubicus a simplifié la prise en main et modernisé les opérations financières de Kaufman & Broad.</li>
                  <li>L’intégration de nouvelles fonctionnalités sur mesure et la possibilité d’évolution constante ont permis à Cubicus de s’adapter précisément aux besoins spécifiques de Kaufman & Broad.</li>
                </ul>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Orientation vers l’innovation : </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Kaufman & Broad envisage d’automatiser davantage ses processus, en particulier dans le rapprochement bancaire, explorant des technologies émergentes comme la <strong>RPA (Robotic Process Automation)</strong> et l’<strong>IA (Intelligence Artificielle)</strong> pour une optimisation avancée.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Sources</h4>
                <a className= "text-rose-700" href="https://corporate.kaufmanbroad.fr/wp-content/uploads/2023/06/K_B_DEU_2022_FR_OPTI-Vdef.pdf" target="_blank" rel="noopener noreferrer"> https://corporate.kaufmanbroad.fr/wp-content/uploads/2023/06/K_B_DEU_2022_FR_OPTI-Vdef.pdf</a><br></br>              
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleKaufman;
