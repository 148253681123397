import React from 'react';
import { Link } from 'react-router-dom';

function BlogSingleEbics() {
  const elem = <strong>EBICS</strong>;
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down"> Qu’est-ce que le protocole EBICS ?  </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/ebics.jpg').default}  alt="News inner" />
                </figure>

                {/* <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Vous connaissez sans doute la « fraude au président », mais connaissez-vous celle liée au changement de RIB ? </h3> */}
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">{elem}, acronyme d'Electronic Banking Internet Communication Standard, est une norme européenne de 2006 à destination du secteur bancaire. Le protocole EBICS vise à standardiser et à sécuriser les échanges de données entre les organisations et leurs établissements bancaires. </p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le protocole {elem} permet à une organisation d’émettre des ordres de paiement ou d’encaissements, de réceptionner ses relevés bancaires et les avis de bonne fin de traitement (Payment Status Report - PSR).</p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">{elem} est un protocole multi-bancaire et permet à une entreprise de contracter avec n’importe quelle banque ayant adopté le protocole {elem} en Europe. </p>


                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Quels sont les deux types de contrat EBICS ? </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>EBICS T</strong> : Assure le transport des fichiers de paiement à la banque.  Les fichiers sont alors mis en attente par la banque, jusqu’à ce qu’une personne habilitée à signer électroniquement – depuis son espace client internet ou depuis son application mobile - donne l’ordre d’exécution.  </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Depuis le 1er janvier 2017, seules les signatures électroniques sur l’espace client sont autorisées pour ce type de contrat.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le protocole <strong>EBICS T</strong>, permet également de réceptionner quotidiennement et automatiquement, les relevés bancaires de l’ensemble de vos banques avec lesquelles vous avez souscrit un contrat <strong>EBICS T</strong></p>
                <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                  <div className="text-center p-4">
                    <p className='text-gray-600 dark:text-gray-400'> CPI assure le transport des fichiers vers les banques</p>
                  </div>
                </div>

                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>EBICS TS</strong> : Assure le transport des ordres et la signature électronique par le biais d’un certificat électronique délivré par un service de certification. Les opérations sont alors exécutées. Ce protocole sécurisé est le plus efficace à ce jour.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le protocole <strong>EBICS TS</strong>, permet également de réceptionner quotidiennement et automatiquement, les relevés bancaires de l’ensemble de vos banques avec lesquelles vous avez souscrit un contrat <strong>EBICS TS</strong>.</p>
                <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                  <div className="text-center p-4">
                    <p className='text-gray-600 dark:text-gray-400'> CPI assure le transport et la signature électronique des fichiers</p>
                  </div>
                </div>


                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Comment mettre en place un contrat EBICS ? </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Pour mettre en place un protocole {elem}, l’entreprise doit se rapprocher de ses partenaires bancaires. Seul un partenaire délivrera un certificat électronique dans le cadre du protocole EBICS TS. Ces contrats EBICS donnent aux utilisateurs des droits sur les comptes, les services mis en place en envoi et réception, les services liés aux comptes, les types de transactions autorisées.</p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Cette mise en place implique plusieurs étapes : <br></br>L'entreprise via son partenaire de communication bancaire, se connecte aux serveurs EBICS de ses partenaires bancaires et sécurise les échanges par une connexion TCP/IP sur HTTPS, assurant ainsi la confidentialité des données.</p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Avant d'être envoyé aux partenaires bancaires, l’entreprise génère un fichier compressé, chiffré grâce à une clé cryptographique, encodé et séparé en plusieurs fichiers XML pour garantir la transmission sécurisée.</p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Dans le cadre de l’EBICS TS avec signature électronique, les partenaires bancaires vont vérifier la conformité de l’ordre grâce à la signature de ce dernier.</p>


                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Le système de sécurisation du protocole EBICS TS </h4>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Dans le cadre de la signature électronique <strong>EBICS TS</strong>, votre partenaire bancaire doit au préalable vous avoir délivré un certificat électronique délivré par une autorité de certification.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La signature d’un ordre de virement donne lieu à la création d’un fichier de signature. La banque dans un second temps vérifiera la conformité de l’ordre et validera le certificat utilisé par l’entreprise.</p>

                <p className="mb-2 text-gray-600 dark:text-gray-400 ">Chaque banque propose trois paires de clés RSA pour garantir la sécurisation des échanges : </p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>Une clé de signature : permet de créer la signature électronique du fichier.</li>
                  <li>Une clé de chiffrement : permet de communiquer la clé AES de chiffrement du fichier.</li>
                  <li>Une clé d'authentification : permet de signer le fichier.</li>
                </ul>

                <p className="mb-2 text-gray-600 dark:text-gray-400 ">Chez CPI, nous avons choisis d’intégrer le protocole {elem} au cœur de nos solutions pour garantir la sécurité inébranlable de chaque échange, ainsi qu’une couche supplémentaire de sécurité à chaque transaction.</p>

                <p className="mb-2 text-gray-600 dark:text-gray-400 "><strong>Optez pour la sécurité, Optez pour CPI.</strong></p>

                <p className="mb-2 text-gray-600 dark:text-gray-400 "><strong>CPI répond aux enjeux organisationnels des entreprises et propose 3 logiciels de communication bancaire :</strong></p>
                <ul className='pt-4 pl-12 list text-gray-600 dark:text-gray-400 mb-10'>
                  <Link to="/opencash"><li><strong>OpenCash : Solution SaaS de communication bancaire et de trésorerie.</strong></li></Link>
                  <Link to="/cubicus"><li><strong>Cubicus : Solution on-premise / offre hébergée de communication bancaire et de trésorerie.</strong></li></Link>
                  <Link to="/exodon"><li><strong>Exodon : Solution SaaS de communication bancaire</strong></li></Link>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleEbics;
