import React from 'react';

function BlogSingleSepa() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down"> Qu’est-ce qu’un prélèvement SEPA ? </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/sepa.jpg').default}  alt="News inner" />
                </figure>

                {/* <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Vous connaissez sans doute la « fraude au président », mais connaissez-vous celle liée au changement de RIB ? </h3> */}
                <p className="mb-4 text-gray-600 dark:text-gray-400 "> Un prélèvement SEPA, ou “Single Euro Payments Area”, est un moyen de paiement électronique qui permet de réaliser des transactions bancaires en euros au sein de la zone SEPA. Le prélèvement SEPA donne au <strong>créancier</strong> la possibilité de demander à son prestataire de services de paiements (généralement une banque) de retirer les fonds du compte du <strong>débiteur</strong>. </p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le débiteur doit avoir au préalable, signé une autorisation : <strong>“Le mandat de prélèvement SEPA” </strong></p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8"> Qu’est-ce qu’un mandat de prélèvement SEPA ? </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Un mandat de prélèvement SEPA est un accord entre deux particuliers ou professionnels moyennant <strong>l’automatisation des paiements</strong> entre eux. Il permet principalement de gagner du temps et de faciliter le paiement des factures récurrentes ou des abonnements par exemple.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>Prenons l’exemple d’un fournisseur réseau et de son client « A »</strong></p>
                
                <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                  <div className="text-center p-4">
                    <p className='text-gray-600 dark:text-gray-400'>Par le biais de ce mandat de prélèvement, le client « A » accepte d’être prélevé à une date prédéterminée, et en euros, la somme relative au prix de son abonnement téléphonique, à condition d’être informé avant chaque prélèvement, suivant les exigences de préavis que pose le mandat de prélèvement SEPA. </p>
                  </div>
                </div>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Comment mettre en place un mandat SEPA ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Mettre en place un mandat de prélèvement SEPA est un moyen de gagner en <strong>efficacité et rapidité</strong>. Cependant, le mandat de prélèvement SEPA doit respecter une structure :</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Avoir comme titre : « Mandat de prélèvement SEPA »</li>
                  <li>Inclure les <strong>mentions suivantes</strong>: « En signant ce formulaire de mandat, vous autorisez (A) [Nom du créancier] à envoyer des instructions à votre banque pour débiter votre compte, et (B) votre banque à débiter votre compte conformément aux instructions de [Nom du créancier]. Vous bénéficiez du droit d’être remboursé par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. Une demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte pour un prélèvement autorisé. » </li>
                  <li>La RUM : ou <strong>Référence Unique de Mandat</strong>, est une référence spécifique mise en place pour identifier de manière distinctive une autorisation de prélèvement SEPA. Cette référence doit impérativement être communiquée au débiteur dès l’émission du mandat et avant le premier prélèvement. </li>
                  <li>L’ICS : ou <strong>Identifiant du Créancier SEPA</strong>, est un identifiant également unique et propre à chaque créancier utilisant le virement SEPA. Il permet la vérification de l’existence effective de chaque mandat de prélèvement SEPA émis, ainsi que son origine, il permet également d’actionner les demandes de contestation ou de remboursements.</li>
                  <li><strong>Les coordonnées du créancier</strong> (nom, adresse, dénomination sociale ou commerciale) </li>
                </ul>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Pour obtenir un ICS, une demande doit être adressée à sa banque (une seule banque en cas de banques multiples), qui est la seule entité habilitée à attribuer cet identifiant.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Comment intégrer une solution de gestion des prélèvements SEPA ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Depuis 2014, les services financiers et comptables des entreprises et associations sont juridiquement responsables de la gestion de leurs mandats. En tant que Directeur Administratif et Financier, Trésorier ou Comptable, vous vous trouvez au cœur de cette responsabilité, et vous avez la tâche de veiller à ce que chaque mandat soit traité en conformité totale avec les réglementations en vigueur.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>OpenCash</strong> intervient pour vous offrir une solution intégrée vous permettant d’<strong>optimiser</strong> la gestion de vos prélèvements SEPA et vous accompagnant jusqu’aux encaissements. </p>


                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Une Gestion Simple et Sécurisée des mandats SEPA</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>OpenCash</strong> facilite l’intégration et la migration des autorisations de prélèvement existantes. Chaque mandat est associé à une référence unique de mandat personnalisée (<strong>RUM</strong>), garantissant ainsi une traçabilité continue. La sécurité est notre préoccupation principale, pour cela, CPI s’appuie sur un système de double contrôle, conformément au principe des quatre yeux. De plus, la dématérialisation des autorisations signées permet d’éviter l’encombrement des documents physiques.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Une Gestion Intelligente des Portefeuilles de Prélèvements</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>OpenCash</strong> permet également d’intégrer et de vérifier des fichiers depuis vos applicatifs métiers dans de multiples formats. La conversion des prélèvements domestiques CFONB 160 est également simplifiée, avec un contrôle du statut du mandat.</p>
                <p className="mb-2 text-gray-600 dark:text-gray-400 ">Vous pouvez également générer des fichiers ad hoc pour une flexibilité optimale dans la gestion de vos portefeuilles de prélèvements. Le client à la possibilité de générer un fichier normalisé de prélèvements SEPA et de le traiter dans la saisie des prélèvements dans notre module flux. </p>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8">SOURCES</h3>
                <a className= "text-rose-700" href="https://www.banque-france.fr/fr/a-votre-service/particuliers/mieux-connaitre-moyens-paiement/prelevement-sepa" target="_blank" rel="noopener noreferrer">https://www.banque-france.fr/fr/a-votre-service/particuliers/mieux-connaitre-moyens-paiement/prelevement-sepa</a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleSepa;
