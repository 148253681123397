import React from 'react';
import { Link } from 'react-router-dom';

import Image01 from '../images/Societe/societe-01.jpg';
import Image02 from '../images/Societe/societe-02.jpg';

function AboutFeatures() {
  return (
    <section className='relative' id='intro'> 
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Items */}
          <div className="max-w-xl mx-auto md:max-w-none space-y-20 pt-12">

            {/* 1st Item */}
            <div className="flex flex-col-reverse md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0">
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h2 className="h3 text-rose-700 font-libre mb-8 mt-4">À propos de CPI </h2>
                <br/>
                <div>
                  <p className="text-lg  text-gray-600 dark:text-gray-400 mb-8">Depuis l'origine, CPI (Compagnie Privée d’Informatique) est un acteur de référence dans le domaine des solutions de gestion financière, multi-protocoles (EBICS TS, Swift Net, SFTP, FTPS) et multi-formats (CFONB, SEPA, ISO, MT×X).</p>
                  <p className="text-lg  text-gray-600 dark:text-gray-400 mb-8">CPI est un des leaders du secteur, reconnu pour son caractère innovant, son agilité et la qualité de son service.</p>
                  <p className="text-lg  text-gray-600 dark:text-gray-400 mb-8">Nous accompagnons plus de 1100 clients et 8 500 utilisateurs dans la gestion quotidienne de leurs flux bancaires.</p>
                  <p className="text-lg  text-gray-600 dark:text-gray-400 mb-8">L'entreprise conçoit, développe, installe, forme et assure elle-même la maintenance de l'ensemble de ses logiciels.</p>
                </div>  
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <img className="mx-auto md:max-w-none shadow-2xl shadow-gray-600" src={Image01} width="540" height="405" alt="About 1" />
                </div>
              </div>
            </div>


            {/* 2nd Item */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0">
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h2 className="h3 text-rose-700 font-libre mb-8 mt-4">Pourquoi choisir CPI ?</h2>
                <br/>
                <div>
                  <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">Nous vous proposons notre savoir-faire et notre expérience du domaine bancaire à travers notre gamme de solutions souples et performantes.</p>
                  <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">Présent depuis 1987, CPI s'appuie sur des connaissances approfondies en matière de normalisation bancaire et de sécurisation de paiement. </p>
                  <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">Grâce à un contact permanent avec nos utilisateurs, nous avons pu affiner nos outils de gestion.</p>
                  <p className="text-lg text-gray-600 dark:text-gray-400 mb-4">Constamment, à l'écoute de ses clients, CPI a fait le choix d'élargir sa gamme de logiciels pour rester au plus près de leurs besoins en terme de : </p>
                  <ul className='list-disc  text-gray-600 dark:text-gray-400 pl-12 mb-8'>
                      <li className="text-md ">Gestion des flux bancaires et de trésorerie : 
                      <Link className="hover:text-rose-700" to="/cubicus"> CUBICUS</Link>,
                      <Link className="hover:text-rose-700" to="/opencash"> OpenCash</Link>.
                      </li>
                      <li className="text-md ">Gestion des pouvoirs bancaires :
                        <Link className="hover:text-rose-700" to="/hepatus"> HEPATUS</Link>.
                      </li>
                      <li className="text-md ">Gestion des remises de chèques à l'encaissement :
                        <Link className="hover:text-rose-700" to="/adioryx"> ADIORYX</Link>.
                      </li>
                      <li className="text-md ">Gestion des mandats SEPA : 
                        <Link className="hover:text-rose-700" to="/ciliaris"> CILIARIS</Link>.
                      </li>
                      <li className="text-md ">Gestion des transferts de flux bancaires :
                        <Link className="hover:text-rose-700" to="/exodon"> EXODON</Link>.
                      </li>
                  </ul>
                </div>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <img className="mx-auto md:max-w-none shadow-2xl shadow-gray-600" src={Image02} width="540" height="405" alt="About 2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutFeatures;
