
import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';
import { Link } from 'react-router-dom';

function Tabs() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab-1]) {
      tabs.current.style.height = tabs.current.children[tab-1].offsetHeight + 'px'
    }
  }
  
  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])  

  return (
    <section className="relative" id='logiciels'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
     
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
            <h2 className="h2 mb-4 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Découvrez toutes nos Solutions et Services </h2>
          </div>
          {/* Section content */}
          <div>
            {/* Tabs buttons */}
            <div className="flex flex-wrap justify-center -m-2" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-tabs]">
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-200 ${tab === 1 && 'hover:bg-gray-600'}hover:bg-gray-200 shadow-xl dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full group transition duration-500 ${tab === 1 && 'bg-rose-700 hover:bg-rose-600 dark:bg-rose-700 dark:hover:bg-rose-700 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                onClick={() => setTab(1)}
              >
                <span className={`text-gray-600 dark:text-gray-300  ${tab === 1 && 'text-white dark:text-rose-700'}`}>OpenCash</span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-200 ${tab === 2 && 'hover:bg-gray-600'}hover:bg-gray-200 shadow-xl dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full group transition duration-500 ${tab === 2 && 'bg-rose-700 hover:bg-rose-600 dark:bg-rose-700 dark:hover:bg-rose-700 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                onClick={() => setTab(2)}
              >
                <span className={`text-gray-600 dark:text-gray-300  ${tab === 2 && 'text-white dark:text-rose-700'}`}>Cubicus</span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-200 ${tab === 3 && 'hover:bg-gray-600'}hover:bg-gray-200 shadow-xl dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full group transition duration-500 ${tab === 3 && 'bg-rose-700 hover:bg-rose-600 dark:bg-rose-700 dark:hover:bg-rose-700 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                onClick={() => setTab(3)}
              >
                <span className={`text-gray-600 dark:text-gray-300  ${tab === 3 && 'text-white dark:text-rose-700'}`}>Hepatus</span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-200 ${tab === 4 && 'hover:bg-gray-600'}hover:bg-gray-200 shadow-xl dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full group transition duration-500 ${tab === 4 && 'bg-rose-700 hover:bg-rose-600 dark:bg-rose-700 dark:hover:bg-rose-700 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                onClick={() => setTab(4)}
              >
                <span className={`text-gray-600 dark:text-gray-300  ${tab === 4 && 'text-white dark:text-rose-700'}`}>Ciliaris</span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-200 ${tab === 5 && 'hover:bg-gray-600'}hover:bg-gray-200 shadow-xl dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full group transition duration-500 ${tab === 5 && 'bg-rose-700 hover:bg-rose-600 dark:bg-rose-700 dark:hover:bg-rose-700 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                onClick={() => setTab(5)}
              >
                <span className={`text-gray-600 dark:text-gray-300  ${tab === 5 && 'text-white dark:text-rose-700'}`}>Exodon</span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-200 ${tab === 6 && 'hover:bg-gray-600'}hover:bg-gray-200 shadow-xl dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full group transition duration-500 ${tab === 6 && 'bg-rose-700 hover:bg-rose-600 dark:bg-rose-700 dark:hover:bg-rose-700 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                onClick={() => setTab(6)}
              >
                <span className={`text-gray-600 dark:text-gray-300  ${tab === 6 && 'text-white dark:text-rose-700'}`}>Adioryx</span>
              </button>
            </div>

            {/* Tabs items */}
            <div className="relative flex flex-col mt-16" data-aos="fade-up" ref={tabs}>

              {/* Item 1 */}
              <Transition
                show={tab === 1}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md my-auto mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2 ">
                    <img className="w-full h-full object-cover rounded-lg shadow-xl " src={require('../images/OpenCash/cash.png').default} width="516" height="387" alt="Tabs 01" />
                  </figure>
                  <div className="relative py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2 font-libre">Reprenez le contrôle de l'ensemble de vos flux bancaires</h4>
                    <p className="text-lg text-gray-600 dark:text-gray-400">OpenCash, la première solution de TMS Full Web de CPI qui concentre l'ensemble de son expertise dans le domaine de la gestion des flux de paiements, trésorerie & Pouvoirs bancaires pour les entreprises.</p>
                    <Link to="/opencash" className="btn-sm text-white text-sm bg-rose-700 hover:bg-rose-600 mt-6"> 
                      En savoir plus
                      <svg className="w-3 h-3 fill-current text-white shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 2 */}
              <Transition
                show={tab === 2}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                    <img className="w-full h-full object-cover rounded-lg shadow-xl " src={require('../images/schema-cubicus.png').default} alt="Tabs 02" />
                  </figure>
                  <div className="relative py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                    <h4 className="h4 mb-2 font-libre">Gérer tous vos flux bancaires et de trésorerie</h4>
                    <p className="text-lg text-gray-600 dark:text-gray-400">Ses modules Paiements, Trésorerie, Chèques, Rapprochement, Export comptable et l'application mobile CUBICUS peuvent être acquis ensemble ou séparément, en mode licence ou en mode hébergé.</p>
                    <Link to="/cubicus" className="btn-sm text-white text-sm bg-rose-700 hover:bg-rose-600 mt-6"> 
                      En savoir plus
                      <svg className="w-3 h-3 fill-current text-white shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 3 */}
              <Transition
                show={tab === 3}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2  ">
                    <img className="w-full h-full object-cover rounded-lg shadow-xl" src={require('../images/schema-hepatus.png').default} width="1032" height="623" alt="Tabs 01" />
                  </figure>
                  <div className="relative py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2 font-libre">Gérer vos pouvoirs bancaires en toute simplicité</h4>
                    <p className="text-lg text-gray-600 dark:text-gray-400">Pionnier des solutions logicielles de gestion de pouvoirs, HEPATUS a su conquérir les Directions Administratives, Financières et les équipes juridiques des plus grandes entreprises, fédérations et associations.</p>
                    <Link to="/hepatus" className="btn-sm text-white text-sm bg-rose-700 hover:bg-rose-600 mt-6"> 
                      En savoir plus
                      <svg className="w-3 h-3 fill-current text-white shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 4 */}
              <Transition
                show={tab === 4}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                    <img className="w-full h-full object-cover rounded-lg shadow-xl" src={require('../images/schema-ciliaris.png').default} alt="Tabs 04" />
                  </figure>
                  <div className="relative py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                    <h4 className="h4 mb-2 font-libre">Gérez vos mandat SEPA en toute facilité</h4>
                    <p className="text-lg text-gray-600 dark:text-gray-400">Opter pour une solution de gestion de vos prélèvements SEPA fiable, souple et complète.
                    Avec CILIARIS, créez vos échéanciers de prélèvements, traitez vos impayés de prélèvements SEPA XML et bien plus encore. 
                    </p>
                    <Link to="/ciliaris" className="btn-sm text-white text-sm bg-rose-700 hover:bg-rose-600 mt-6"> 
                      En savoir plus
                      <svg className="w-3 h-3 fill-current text-white shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 5 */}
              <Transition
                show={tab === 5}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              > 
                  <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2 ">
                    <img className="w-full h-full object-cover rounded-lg shadow-xl" src={require('../images/schema-exodon.png').default} width="516" height="387" alt="Tabs 01" />
                  </figure>
                  <div className="relative py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2 font-libre">Gérez vos échanges bancaires en toute simplicité</h4>
                    <p className="text-lg text-gray-600 dark:text-gray-400">EXODON est une plateforme internet de communication bancaire pour vos envois et réceptions de fichiers bancaires. Mais c'est aussi un service SEPAmail innovant pour faciliter les règlements de vos clients.</p>
                    <Link to="/exodon" className="btn-sm text-white text-sm bg-rose-700 hover:bg-rose-600 mt-6"> 
                      En savoir plus
                      <svg className="w-3 h-3 fill-current text-white shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
                 
              </Transition>
              <Transition
                show={tab === 6}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                 <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                    <img className="w-full h-full object-cover rounded-lg shadow-xl" src={require('../images/schema-adioryx.png').default} width="516" height="387" alt="Tabs 02" />
                  </figure>
                  <div className="relative py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                    <h4 className="h4 mb-2 font-libre">Sécurisez et optimisez vos remises de chèques</h4>
                    <p className="text-lg text-gray-600 dark:text-gray-400">ADIORYX, une solution de pointe couplant des scanners performants et une interface logicielle simple et intuitive pour optimiser tout votre processus d'encaissement des chèques.</p>
                    <Link to="/adioryx" className="btn-sm text-white text-sm bg-rose-700 hover:bg-rose-600 mt-6"> 
                      En savoir plus
                      <svg className="w-3 h-3 fill-current text-white shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Tabs;
