import React from 'react';

function BlogSinglePasteur() {

    return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">Succès à l’Institut Pasteur : Comment Cubicus redéfinit la Gestion de Trésorerie ?</h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
              <figure className="py-8">
                  <img className="w-full " src={require('../images/institut.jpg').default}  alt="News inner" />
                </figure>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>La solution Cubicus a connu plein d'évolutions au fil des années pour s'adapter aux nouvelles normes et répondre à nos besoins en constante évolution. Nous avons pu importer de nouveaux modules, comme le module parapheur qui n'existait pas auparavant, ainsi que d'autres produits tels que la gestion de devises et des taux. L'évolution des outils s'est révélée très fluide et a bien suivi nos besoins changeants, et léquipe est clairement satisfaite de l'utilisation de Cubicus qui a apporté une tranquillité retrouvée.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>EMMANUEL SALE<br/>Institut Pasteur</p>
                    </div>
                </div>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>Cubicus gère la trésorerie quotidiennement, recevant et gérant tous les flux. En fin de mois, il génère les reportings mensuels essentiels pour le suivi du budget et la planification à long terme, et travailler avec l'outil est vraiment pratique.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>VALERIE LE ROY<br/>Institut Pasteur</p>
                    </div>
                </div>

                <div className="relative flex items-center z-10 border-2 border-gray-200 dark:border-gray-800 -skew-x-3 mb-8">
                    <div className="text-center mb-4 p-6">
                        <svg className="m-2 fill-rose-700" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-gray-600 dark:text-gray-400'>Avant Cubicus, on avait une autre solution dont l'assistance était défaillante, les délais d'intervention étaient longs, et pour un petit problème technique, nous perdions l'accès à la trésorerie pendant 2-3 jours. On voulait une solution solide, réactive, et à l'écoute de modifications, avec des tarifs raisonnables.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-center text-rose-700 mt-12'>EMMANUEL SALE<br/>Institut Pasteur</p>
                    </div>
                </div>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8">Présentation de l’entreprise :</h3>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">L’institut Pasteur est une fondation française privée à but non-lucratif, reconnue d’utilité publique, qui se consacre à la recherche fondamentale, l’enseignement et la veille sanitaire. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>Secteur d’activité</strong> : recherche biomédicale</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 mb-8"><strong>Taille</strong> : 2600 Salariés</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Points forts mis en avant :</h4>
                <ul className='pt-8 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Cubicus a remplacé une solution antérieure avec des fonctionnalités robustes, mais une assistance défaillante, offrant ainsi une alternative solide et réactive.</li>
                  <li>L’installation et l’intégration de Cubicus se déroulent rapidement, avec une formation efficace, assurant une autonomie immédiate.</li>
                  <li>L’équipe CPI est reconnue pour son service de qualité, sa disponibilité constante, et son engagement à résoudre les problèmes.</li>
                </ul>
                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Satisfaction et performance :</h4>
                <ul className='pt-8 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>L’équipe trésorerie de l’Institut Pasteur attribue une part de son succès à Cubicus, soulignant sa fluidité et son impact positif sur le bien-être au travail.</li>
                  <li>Cubicus gère la trésorerie quotidiennement, génère des reportings mensuels essentiels, et évolue constamment pour répondre aux besoins changeants de l’Institut Pasteur.</li>
                </ul>
                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Évolution et adaptabilité :</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Cubicus a connu de nombreuses évolutions au fil des années pour s’adapter aux nouvelles normes et répondre aux besoins changeants, intégrant de nouveaux modules et fonctionnalités.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSinglePasteur;
