import React from 'react';

function CarouselSociete() {

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          {/* Carousel area */}
          <div className="max-w-5xl mx-auto">
            {/* Carousel */}
            <div className="relative" data-aos="fade-down">
              {/* Testimonials */}
              <div className="relative flex items-center z-10 ">
                    <div className="text-center font-medium mb-4 p-6">
                        <svg className="fill-rose-700 mb-4" width="20" height="16" viewBox="0 0 20 16">
                          <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                        </svg>
                        <p className='text-lg md:text-xl text-gray-600 dark:text-gray-400  mb-4'> Face à l'explosion des flux, protocoles et normes bancaires, les services financiers et comptables ont besoin de logiciels performants pour optimiser leurs échanges.</p>
                        <p className='text-lg md:text-xl text-gray-600 dark:text-gray-400  mb-4'>Depuis 1980, nos équipes ont su allier une haute maîtrise technologique à une connaissance fine des environnements comptables et financiers, pour offrir à nos clients plus d'efficacité, plus de sûreté et plus de simplicité à l'ensemble de leurs processus bancaires.</p>
                        <p className='text-lg md:text-xl text-gray-600 dark:text-gray-400 '>Nous sommes fiers des gains de productivité et de sécurité que nous apportons chaque jour aux nombreuses entreprises, associations et administrations qui nous renouvellent, année après année, leur confiance.</p>
                        <svg className="grow float-right fill-rose-700" width="60" height="48" viewBox="0 0 440 352">
                            <path d="M131.364 94.857 C 171.806 130.381,183.436 160.174,153.976 152.780 C 133.352 147.603,130.233 149.819,130.233 169.643 C 130.233 197.068,161.106 227.677,181.049 220.025 C 219.652 205.212,181.795 109.421,126.904 83.019 C 110.763 75.256,111.610 77.505,131.364 94.857 M242.576 110.912 C 273.782 142.119,277.632 158.454,252.213 151.807 C 229.152 145.776,221.040 160.402,231.779 188.648 C 250.708 238.434,304.036 224.833,293.644 172.870 C 288.449 146.897,237.782 83.721,222.147 83.721 C 218.427 83.721,227.621 95.957,242.576 110.912" />
                        </svg>
                        <p className='text-md text-center text-rose-700 mt-12'>Guillaume GENESLAY - CEO CPI</p>
                    </div>
              </div>
              <div className="absolute inset-0 transform border-2 border-gray-200 dark:border-gray-800 pointer-events-none" aria-hidden="true"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CarouselSociete;