import React from 'react';

function BlogSingleGPI() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down"> Le service GPI de SWIFT : le nouveau standard pour les paiements internationaux </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <h3 className="mb-4 h4 dark:text-gray-300 ">Qu’est-ce que GPI for corporates de SWIFT ?</h3>

                <figure className="py-8">
                  <img className="w-full " src={require('../images/swiftgpi.jpg').default}  alt="News inner" />
                </figure>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> GPI for corporates de SWIFT : De quoi s’agit-il ? </h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">GPI, de son nom complet « Global Payment Innovation » est une technologie mise en place par SWIFT (Society For Worldwide Interbank Financial Telecommunication) en 2017. L’objectif principal de cette extension de SWIFT for corporates consiste à améliorer la qualité des paiements internationaux, précédemment connus pour leur lenteur et leur opacité. GPI est disponible gratuitement pour les utilisateurs de SWIFT, et est intégré dans les Treasury Management System.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Mais comment SWIFT a réussi à améliorer ce processus précédemment fastidieux ?</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">En se concentrant sur trois piliers :</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>La rapidité</li>
                  <li>La traçabilité</li>
                  <li>La transparence</li>
                </ul>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Ce choix stratégique vient répondre à un réel besoin de fluidité dans les paiements transfrontaliers. Avec une croissance du commerce mondial, (il a été multiplié par 7,4 depuis 1980.<span className= "text-rose-700"> Source : FMI, World Economic Outlook, Avril 2022</span>), les technologies en lien avec les paiements internationaux doivent elles aussi évoluer. Effectivement, les nombreux intermédiaires présents dans le processus impactaient fortement le bon déroulement de l’échange.</p>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Une innovation avec une réelle valeur ajoutée </h3>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">GPI vient donc faciliter le quotidien des entreprises et des financiers qui disposent enfin d’informations claires et fiables concernant la durée de l’envoi du flux, le prix total des frais bancaires et leur origine, ainsi que le moment précis auquel le paiement a été crédité. Avant GPI, ces informations n’étaient pas disponibles, ce qui constituait un réel frein à l’utilisation des paiements internationaux. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Effectivement, grâce au GPI tracker, les virements internationaux sont plus rapides : plus de 90% des paiements sont effectués en moins de 24 heures. Un autre avantage majeur est la traçabilité qu’offre GPI : grâce au UETR (Unique End-to-End Transaction Reference), les trésoriers peuvent initier un paiement avec un numéro unique depuis leur TMS et suivre la transaction en détail et à tout moment. En cas d’erreur ou de fraude, avec la fonctionnalité « stop and recall », le flux peut être figé. Pour faciliter le suivi, une notification est envoyée quand le paiement est dans le réseau et à chaque mise à jour du statut. Une preuve d’envoi digitale est aussi disponible</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La transparence est également un atout de GPI. En effet, afin d’affiner les prévisions, l’extension permet un détail de l’ensemble des frais bancaires appliqués (leur montant et leur origine). Les frais de taux de change sont également calculés. Les financiers ne font donc plus face à l’opacité concernant les montants des frais administrés par les banques durant la transaction, leur permettant d’optimiser leur trésorerie et d’avoir de meilleures prévisions. </p>

                <h3 className="mb-4 h4 dark:text-gray-300 pt-8">Vers une standardisation majeure de SWIFT GPI</h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Ce modèle est en pleine expansion : aujourd’hui 4200 institutions financières utilisent SWIFT GPI. Au vu du succès de GPI, SWIFT a lancé SWIFT GO en juin 2021, afin de permettre aux petites et moyennes entreprises d’effectuer des envois à faible valeur de façon rapide, fiable, prédictiblesécurisée et à des prix attractifs. </p>
                <h3 className="mb-4 h4 dark:text-gray-300 pt-8">GPI au sein des TMS </h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Les TMS permettent donc de visualiser ces informations envoyées par les banques via des fichiers XML. Chez CPI Software, les données sont visibles directement depuis la plateforme du logiciel Open Cash.</p>
                <h3 className="mb-4 h4 dark:text-gray-300 pt-8">SOURCES</h3>
                <a className= "text-rose-700" href="https://www.swift.com/fr/node/168721" target="_blank" rel="noopener noreferrer">https://www.swift.com/fr/node/168721</a><br></br>
                <a className= "text-rose-700" href="https://www.ceviant.finance/resources/making-international-payments-easier-faster-and-more" target="_blank" rel="noopener noreferrer">https://www.ceviant.finance/resources/making-international-payments-easier-faster-and-more</a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleGPI;