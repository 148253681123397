import React from 'react';
import { Link } from 'react-router-dom';
function BlogSingle() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">eBAM : Sécurisation et digitalisation des pouvoirs bancaires</h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />

              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <h3 className="mb-4 h4 dark:text-gray-300 "> Qu’est-ce que l’eBAM ?</h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">
                eBAM ou Electronic Bank Account Management, désigne <strong>la gestion dématérialisée des comptes bancaires</strong> et des différentes opérations qui y sont associées. Il s'agit pour le client d'envoyer des instructions à l'établissement bancaire, telles que :
                </p>
                <ul className='pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>La gestion de compte (autorisations de signature, restrictions d'autorisation, etc..)</li>
                  <li>L’ouverture et la clôture des comptes</li>
                  <li>La délégation de pouvoirs bancaires</li>
                </ul>

                <h3 className="mb-4 h4 dark:text-gray-300"> Quels sont les objectifs de l’eBAM ? </h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">La mise en place de <strong>l’eBAM</strong> chez CPI vise à :</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-10'>
                  <li>La Sécurité renforcée grâce à la signature électronique</li>
                  <li>L’arrêt des courriers RAR*  coûteux, chronophages et peu sécurisés </li>
                  <li>L’accusé de réception des banques</li>
                  <li>Le gain de temps : prise en compte immédiate des éléments envoyés</li>
                </ul>
                <figure className="mb-8">
                  <img className="w-full" src={require('../images/blogIntro.jpg').default}  alt="News inner" />
                </figure>
               
                <p className="mb-8 text-gray-600 dark:text-gray-400 ">
                En 2020, CPI a lancé, avec un de ses principaux clients, un projet eBAM. Ce projet implique 4 banques et a pour but de transmettre les pouvoirs eBAM via le protocole eBICS.
                        </p>
                <p className="mb-8 text-gray-600 dark:text-gray-400">
                En environnement de recette, CPI via son logiciel HEPATUS  a transmis ces premiers messages eBAM avec succès.
                </p>

                <p className="mb-8 text-gray-600 dark:text-gray-400">
                La mise en production de ce processus avec les banques BNP PARIBAS  et Crédit Agricole Corporate and Investment Bank est prévue pour le premier trimestre 2022. Les envois aux banques Société Générale et Crédit mutuel sont prévus pour fin 2022.
                        </p>
                <p className="mb-8 text-gray-600 dark:text-gray-400">
                L’eBAM, géré par notre outil <Link className="hover:text-rose-700" to='/hepatus'>Hepatus</Link>, va rendre la gestion des pouvoirs bancaires plus fluide, plus rapide et plus sécurisée.
                </p>  
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogSingle;