import React, {useEffect} from 'react';

import Header from '../../partials/Header';
import PageIllustration from '../../partials/PageIllustration';
import PageIllustration2 from '../../partials/PageIllustration2';
import BlogSingleDélaisPaiement from '../../partials/BlogSingleDélaisPaiement';
import Footer from '../../partials/Footer';

function BlogPostDélaisPaiement() {

  useEffect(() => {
    document.title = `Impact des délais de paiement sur votre trésorerie | CPI Software`
  }, [])
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
          <PageIllustration />
          <PageIllustration2 />
        </div>
        {/*  Page sections */}
        <BlogSingleDélaisPaiement />
      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default BlogPostDélaisPaiement;