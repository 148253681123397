import React from 'react';

function AdioryxComponent() {
  return (
    <section>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Line */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
             <h3 className="h3 mb-8 dark:text-gray-300 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Sécurisez et optimisez vos remises de chèques</h3>
              <p className=" text-xl text-gray-600 dark:text-gray-400" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">ADIORYX, une solution de pointe couplant des scanners performants et une interface logicielle simple et intuitive pour optimiser tout votre processus d'encaissement des chèques. <br></br>Les services financiers et comptables sont de plus en plus nombreux à faire le choix d'ADIORYX. Grâce aux technologies innovantes et aux processus solides élaborés par CPI, vous trouverez une réponse efficace, rapide et hautement sécurisée pour automatiser tous vos encaissements de chèques.</p>
          </div>

          {/* Features */}
          <div className="max-w-xl mx-auto md:max-w-none space-y-20 pt-8">

            {/* 1st Feature */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0">
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Encaissez plus rapidement vos chèques :</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                   <li>Votre solution ADIORYX récupère automatiquement toutes les informations de vos chèques.</li>
                   <li>Endossez automatiquement tous vos chèques.</li>
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none -z-10" aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600" src={require('../images/Adioryx/1.jpg').default} width="350" alt="Adioryx 1"/>
                </div>
              </div>
            </div>


            {/* 2nd Feature*/}
            <div className="flex flex-col-reverse md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0 mt-6">  
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Sécurisez toutes vos remises de chèques : </h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                    <li>Limitez les risques d'erreur de saisie manuelle.</li>
                    <li>Le montant de la remise est contrôlé automatiquement par ADIORYX</li>
                    <li>Le chèque remisé est scanné et archivé au format PDF.</li>
                    <li>Bénéficiez d'une traçabilité totale sur tous vos encaissements de chèques.</li>   
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none-z-10 " aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600"  src={require('../images/Adioryx/2.jpg').default} width="350" alt="Adioryx 2" />
                </div>
              </div>
            </div>


            {/* 3rd Feature */}
            <div className="flex flex-col-reverse md:flex-row-reverse md:items-center md:space-x-reverse lg:space-x-reverse xl:space-x-reverse md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-4 space-y-reverse md:space-y-0">
              {/* Content */}
              <div className="md:min-w-[30rem]" data-aos="fade-left" data-aos-duration="1500">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 mt-6 md:mt-0">Accélérez votre processus de remise de chèques :</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                    <li>Imprimez vos bordereaux de remise de chèques.</li>
                    <li>Créez automatiquement vos images-chèques pour la banque.</li>
                    <li>Optimisez ainsi votre trésorerie.</li>
                    <li>Générez facilement tous vos fichiers comptables.</li>
                </ul>
              </div>
              {/* Image */}
              <div className="flex justify-center items-center" data-aos="fade-right" data-aos-duration="1500">
                <div className="relative">
                  <div className="absolute inset-0 pointer-events-none -z-10" aria-hidden="true"></div>
                  <img className="mx-auto md:max-w-none rounded-lg shadow-xl shadow-gray-600" src={require('../images/Adioryx/3.jpg').default} width="350" alt="Adioryx 3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AdioryxComponent;
