import React from 'react';

function OpenCashComponent01() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
            <h2 className="h3 mb-8 dark:text-gray-300 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Reprenez le contrôle de l'ensemble de vos flux bancaires.</h2>
             <p className="text-xl text-gray-600 dark:text-gray-400">Les 5 leviers OpenCash qui font la différence :</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none mt-28" data-aos-id-blocks>
            {/* 1st item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
             <img  src={require('../images/OpenCash/flow.png').default} width="64" alt='Levier 1'/>
              <h4 className="h4 mt-4 mb-2 dark:text-gray-300 text-center">Une solution intégrée </h4>
              <p className="text-lg text-gray-600 dark:text-gray-400 text-center">qui répond aux attentes des métiers de la direction financière.</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
              <img  src={require('../images/OpenCash/monitor.png').default} width="64" alt='Levier 2'/>
              <h4 className="h4 mt-4 mb-2 dark:text-gray-300 text-center">Une plateforme flexible</h4>
              <p className="text-lg text-gray-600 dark:text-gray-400 text-center">entièrement paramétrable, conçue pour ses différents utilisateurs au plus près de leurs besoins.</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]">
              <img  src={require('../images/OpenCash/shield.png').default} width="64" alt='Levier 3'/>
              <h4 className="h4 mt-4 mb-2 dark:text-gray-300 text-center">Une sécurité maximale</h4>
              <p className="text-lg text-gray-600 dark:text-gray-400 text-center">pour l'ensemble de vos saisies et échanges.</p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]">
              <img  src={require('../images/OpenCash/database.png').default} width="64" alt='Levier 4'/>
              <h4 className="h4 mt-4 mb-2 dark:text-gray-300 text-center">Une couverture fonctionnelle large </h4>
              <p className="text-lg text-gray-600 dark:text-gray-400 text-center">du front office au back office sans oublier les fonctions transverses de pilotage.</p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
              <img  src={require('../images/OpenCash/cloud-data.png').default} width="64" alt='Levier 5'/>
              <h4 className="h4 mt-4 mb-2 dark:text-gray-300 text-center">Un logiciel full Web </h4>
              <p className="text-lg text-gray-600 dark:text-gray-400 text-center">accessible partout en mode SaaS via un simple navigateur ou via App mobile IOS et Android.</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
export default OpenCashComponent01;
  