import React from 'react';

function BlogSingleAchat() {
  const elem = <strong>CPI</strong>;
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down">Le parcours d’achat client chez CPI : Une approche personnalisée de la gestion financière</h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/achat.jpg').default}  alt="News inner" />
                </figure>

                {/* <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Vous connaissez sans doute la « fraude au président », mais connaissez-vous celle liée au changement de RIB ? </h3> */}
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Bienvenue chez {elem}, votre partenaire de confiance dans la gestion financière de votre entreprise.<br/>  Nous sommes conscients que chaque entreprise a des besoins spécifiques, c’est la raison pour laquelle nous avons conçu nos logiciels pour être entièrement <strong>personnalisables</strong>.<br/>Découvrons ensemble comment nous rendons votre expérience unique, du début à la fin. </p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Étape 1 : Analyse des besoins et définition des exigences fonctionnelles</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le premier pas vers une gestion financière optimale commence toujours par une compréhension approfondie de vos besoins !</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Notre équipe d’experts travaille en étroite collaboration avec vous pour analyser et comprendre vos exigences fonctionnelles. Nos logiciels sont <strong>entièrement</strong> paramétrables, et conçus pour s’adapter aux besoins les plus spécifiques de votre entreprise indépendante, ainsi qu’aux enjeux de groupe et aux exigences strictes qui y sont liées, avec une flexibilité totale.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Pour les groupes internationaux, prenons l’exemple de notre solution OpenCash qui fonctionne également en mode « silo ». Chaque entité est configurée avec ses propres utilisateurs, tiers et banques, et les utilisateurs n’ont pas accès aux autres sociétés du groupe, assurant une séparation stricte. Cependant, des administrateurs globaux peuvent être identifiés pour garantir une gestion centralisée.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Étape 2 : L’identification des développements spécifiques </h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Chaque entreprise est unique, et c’est pourquoi nos experts veillent à identifier soigneusement les éventuels développements spécifiques nécessaires pour répondre parfaitement à vos attentes, et à ce que chaque modification soit réalisée avec précision pour vous garantir une solution adaptée à vos besoins.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Étape 3 : Validation et conception du projet</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Avant de passer à la réalisation, nous nous assurons de la validation complète du projet. La conception détaillée qui en découle est le résultat d’une collaboration transparente entre nos chefs de projets et vos équipes, garantissant ainsi une vision partagée et une compréhension commune des objectifs. </p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Étape 4 : Réalisation, Paramétrages, Installation et mise en service de tests</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Maintenant, que tout est en place, nous passons à la phase de réalisation et de mise en service.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Chaque installation est effectuée avec le plus grand soin. Des tests rigoureux garantissent la fiabilité et l’efficacité de chaque aspect de la solution, et c’est notre manière de nous assurer que tout fonctionne comme un charme avant que vous ne preniez le volant.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Vous l’aurez sûrement compris, nous ne laissons rien au hasard, sauf peut-être le choix de la pause-café ! </p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Étape 5 : Formation des équipes</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Nous croyons fermement que la réussite de l’implémentation réside dans la formation adéquate de vos équipes. C’est la raison pour laquelle nos experts s’engagent à vous guider et à vous former, permettant ainsi une maîtrise complète des fonctionnalités de nos logiciels.</p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Étape 6 : Support et maintenance</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Notre équipe humaine de support assure un support continu et une maintenance proactive pour garantir que votre solution fonctionne de manière optimale et en tout temps.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Nous sommes fiers de fournir tous ces services en interne et sans externalisation. Cela signifie une communication fluide, une compréhension approfondie de vos besoins ainsi qu’une réactivité inégalée.</p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 pt-8">Que vous dirigiez une entreprise indépendante ou que vous fassiez partie d’un groupe international, {elem} s’engage à offrir à chaque client une expérience de gestion financière adaptée à ses besoins uniques, et notre engagement envers une expérience de gestion financière sur mesure se manifeste à chaque étape du parcours d’achat. </p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Propulsez votre entreprise vers l’efficacité et la réussite !</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleAchat;
