import React from 'react';

function FeaturesAnimation() {
  return (
    <section id='partners'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800" data-aos="fade-up">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 font-libre mb-4">Nos <span className='text-rose-700 '>Partenaires</span> & <span className='text-gray-500'>Revendeurs</span></h1>
          </div>
          {/* Custom animation */}
          <div className="max-w-3xl mx-auto">
            <div className="relative flex justify-center items-center overflow-hidden">
              {/* Big circle behind: light layout */}
              <svg className="dark:hidden" width="432" height="432" viewBox="0 0 432 432" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="48.919%" id="prefix__a">
                    <stop stopColor="#e11d48" stopOpacity=".64" offset="0%" />
                    <stop stopColor="#e11d48" stopOpacity="0" offset="100%" />
                  </radialGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <circle fill="url(#prefix__a)" cx="216" cy="216" r="216" />
                  <g transform="translate(22 22)" stroke="#1D1D20">
                    <circle strokeOpacity=".04" cx="194" cy="194" r="193.5" />
                    <circle strokeOpacity=".08" strokeWidth="2" cx="194" cy="194" r="153" />
                    <circle strokeOpacity=".16" strokeWidth="2.5" cx="194" cy="194" r="112.75" />
                  </g>
                </g>
              </svg>
              {/* Big circle behind: dark layout */}
              <svg className="hidden dark:block" width="432" height="432" viewBox="0 0 432 432" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="50%" id="circleill_a">
                    <stop stopColor="#1D1D21" stopOpacity="0" offset="0%" />
                    <stop stopColor="#2E2E33" stopOpacity=".32" offset="100%" />
                  </radialGradient>
                </defs>
                <circle cx="216" cy="216" r="216" fill="url(#circleill_a)" fillRule="evenodd" />
              </svg>
              {/* Moving tags */}
              <div className="absolute inset-0">
                <a className="transition ease-in-out delay-150 absolute font-medium px-4 py-2 text-white dark:text-gray-400 bg-gradient-to-tr from-gray-600 to-gray-400 dark:bg-gray-500 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-1 mt-72" href='https://www.ldsysteme.fr/' target="_blank" rel="noopener noreferrer">LD Système</a>
                <a className="absolute font-medium px-4 py-2 text-white text-white dark:text-gray-400 bg-gradient-to-tr from-gray-600 to-gray-400 dark:bg-gray-500 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-2" href='https://www.ebp.com/applications-editeurs-partenaires-ebp/cpi' target="_blank" rel="noopener noreferrer">EBP (édition de logiciel de comptabilité & RH)</a>
                <a className="absolute font-medium px-4 py-2 text-white dark:text-rose-600 bg-gradient-to-tr from-rose-700 to-rose-600 dark:bg-rose-700 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-2 mt-40" href='https://www.ppi.de/fr/' target="_blank" rel="noopener noreferrer">PPI (Partenaire technologique Ebics)</a>
                <a className="absolute font-medium px-4 py-2 text-white dark:text-rose-600 bg-gradient-to-tr from-rose-700 to-rose-600 dark:bg-rose-700 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-3 mt-80 top-4" href='https://www.panini.com/en' target="_blank" rel="noopener noreferrer">Panini</a>
                <a className="absolute font-medium px-4 py-2 text-white dark:text-rose-600 bg-gradient-to-tr from-rose-700 to-rose-600 dark:bg-rose-700 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-4 mt-56" href='https://www.ikoula.com/fr' target="_blank" rel="noopener noreferrer">Ikoula</a>
                <a className="absolute font-medium px-4 py-2 text-white dark:text-rose-600 bg-gradient-to-tr from-rose-700 to-rose-600 dark:bg-rose-700 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-5 mt-14" href='https://www.sis-id.com/' target="_blank" rel="noopener noreferrer">SIS ID</a>
                <a className="absolute font-medium px-4 py-2 text-white dark:text-rose-600 bg-gradient-to-tr from-rose-700 to-rose-600 dark:bg-rose-700 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-9 mt-80 top-4" href='https://www.swift.com/' target="_blank" rel="noopener noreferrer">Swift</a>
                <a className="absolute font-medium px-4 py-2 text-white dark:text-gray-400 bg-gradient-to-tr from-gray-600 to-gray-400 dark:bg-gray-500 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-6 mt-40" href='https://www.ebsgroup.fr/' target="_blank" rel="noopener noreferrer">EBS Group</a>
                <a className="absolute font-medium px-4 py-2 text-white dark:text-rose-600 bg-gradient-to-tr from-rose-700 to-rose-600 dark:bg-rose-700 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-7" href='https://www.parascript.com/' target="_blank" rel="noopener noreferrer">Parascript</a>
                <a className="absolute font-medium px-4 py-2 text-white text-white dark:text-gray-400 bg-gradient-to-tr from-gray-600 to-gray-400 dark:bg-gray-500 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-8 mt-28" href='https://www.sigma-occitanie.fr/' target="_blank" rel="noopener noreferrer"> SIGMA Occitanie </a>
                <a className="absolute font-medium px-4 py-2 text-white text-white dark:text-gray-400 bg-gradient-to-tr from-gray-600 to-gray-400 dark:bg-gray-500 dark:from-transparent dark:to-transparent dark:bg-opacity-25 rounded-full inline-flex items-center justify-center tags-animation tags-5 mt-80 top-4" href='https://www.nibelis.com/' target="_blank" rel="noopener noreferrer">NIBELIS</a>
              </div>
              {/* Inner cricle */}
              <svg className="absolute rounded-full shadow-xl" width="148" height="148" viewBox="0 0 148 148" xmlns="http://www.w3.org/2000/svg">
                <circle cx="74" cy="74" r="74" fill="#2E2E33" fillRule="evenodd" />
              </svg>
              {/* Logo */}
              <svg className="absolute w-12 h-12 animate-spin" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="logo_a">
                    <stop stopColor="#be123c" offset="0%" />
                    <stop stopColor="#6E6E76" offset="100%" />
                  </linearGradient>
                  <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="logo_b">
                    <stop stopColor="#be123c" offset="0%" />
                    <stop stopColor="#be123c" stopOpacity="0" offset="100%" />
                  </linearGradient>
                </defs>
                <path d="M32 16h-8a8 8 0 10-16 0H0C0 7.163 7.163 0 16 0s16 7.163 16 16z" fill="url(#logo_a)" />
                <path d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16h8a8 8 0 1016 0h8z" fill="url(#logo_b)" />
              </svg>
              {/* Side gradients */}
              <div className="absolute top-0 bottom-0 left-0 w-20 bg-gradient-to-r from-white dark:from-gray-900" aria-hidden="true"></div>
              <div className="absolute top-0 bottom-0 right-0 w-20 bg-gradient-to-l from-white dark:from-gray-900" aria-hidden="true"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FeaturesAnimation;
