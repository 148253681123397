import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function BlogSingleSaas() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {/* Article header */}
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h2 font-libre mb-4" data-aos="fade-down"> Qu’est-ce que le SaaS ? </h1>
                </div>
                {/* Article meta */}
              </header>
              <hr className="w-2xl h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />
              {/* Article content */}
              <div className="text-lg mt-4" data-aos="fade-up" data-aos-delay="450">
                <figure className="py-8">
                  <img className="w-full " src={require('../images/saas.jpg').default}  alt="News inner" />
                </figure>

                {/* <h3 className="mb-4 h4 dark:text-gray-300 pt-8"> Vous connaissez sans doute la « fraude au président », mais connaissez-vous celle liée au changement de RIB ? </h3> */}
                <p className="mb-4 text-gray-600 dark:text-gray-400 "><strong>Le SaaS (Software as a Service)</strong> est un modèle de distribution de logiciels basé sur le <strong>cloud</strong>, Il permet l'accès à des applications directement via n’importe quel <strong>navigateur internet</strong>.</p>

                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le modèle SaaS répond à ce jour à de nombreux besoins par son accessibilité, son interface simplifiée, ainsi que ses mises à jour centralisées. De plus, le SaaS vous libère des contraintes matérielles et des coûts qu’occasionnent la maintenance des serveurs. Cependant, une <strong>connexion internet</strong> est généralement requise pour utiliser les applications SaaS.  </p>

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8"> Comment fonctionne un logiciel SaaS ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Les utilisateurs d’une application SaaS ne paient généralement pas de licences d’utilisation mais s’approprient plutôt un droit d’accès sous forme d’un <strong>abonnement</strong> mensuel ou annuel. Aucune installation sur les serveurs locaux de l’entreprise n’est nécessaire.</p>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">L’accès au logiciel se fait par le biais d’un identifiant et d’un mot de passe uniques à chaque compte d’utilisateur selon les droits qui lui sont accordés. Ainsi, le fournisseur prend en charge la gestion du matériel et des logiciels, et assure lui-même la disponibilité, le fonctionnement et la sécurité de l’application.</p>
                {/*
                <div style={{borderColor: '#e11d48'}}className="border-2 mb-6">
                  <div className="text-center p-4">
                    <p className='text-gray-600 dark:text-gray-400'>Par le biais de ce mandat de prélèvement, le client « A » accepte d’être prélevé à une date prédéterminée, et en euros, la somme relative au prix de son abonnement téléphonique, à condition d’être informé avant chaque prélèvement, suivant les exigences de préavis que pose le mandat de prélèvement SEPA. </p>
                  </div>
                </div> */}

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Pourquoi opter pour un logiciel SaaS ?</h4>
                <p className="mb-4 text-gray-600 dark:text-gray-400 ">Le SaaS offre une multitude d'avantages tels que l'accessibilité depuis n’importe quel appareil, la mobilité, ainsi qu’une gestion opérationnelle plus efficace. De plus, il présente des <strong>coûts initiaux réduits</strong> par rapport à une installation classique de logiciels, ce qui le rend abordable à un plus grand nombre d'entreprises.</p>
                <ul className='pt-4 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li><strong>Accessible où que vous soyez</strong> : Vous accédez à votre application SaaS depuis votre ordinateur, tablette ou smartphone quel que soit le système d’exploitation utilisé, il suffit de l’exécuter depuis n’importe quel navigateur internet ce qui rend les applications SaaS extrêmement polyvalentes et mobiles. </li>
                  <li><strong>Adaptez les modules en fonction de vos besoins</strong>: Ajustez votre plan de facturation en fonction de votre utilisation. Le SaaS est <strong>évolutif</strong> et vous permet d’adapter votre consommation en fonction de l’évolution que prend votre organisation. </li>
                  <li><strong>Simplifiez votre gestion</strong> : L’achat et la maintenance des serveurs qui hébergent vos logiciels ne sont plus à votre charge. Les sauvegardes si importantes en cas de problème ne sont plus de votre responsabilité.<br/>Le <strong>SaaS</strong> vient justement remplacer ce besoin. En démocratisant l’obtention et l’utilisation de logiciels performants en <strong>full web</strong>, la plus petite entreprise peut désormais avoir accès à des logiciels que seules les plus grosses structures pouvaient se procurer.</li>
                  <li><strong>Restez à jour</strong> : Plutôt que de devoir acheter régulièrement de nouveaux logiciels, les utilisateurs peuvent compter sur le fournisseur SaaS pour programmer des <strong>mises à jour</strong> automatique et gérer l’ajout de patchs correctifs.</li>
                </ul>
                {/* <p className="mb-4 text-gray-600 dark:text-gray-400 ">Pour obtenir un ICS, une demande doit être adressée à sa banque (une seule banque en cas de banques multiples), qui est la seule entité habilitée à attribuer cet identifiant.</p> */}

                <h4 className="mb-4 h4 dark:text-gray-300 pt-8">Découvrez <Link to="/opencash" className="hover:text-rose-700 transition duration-150 ease-in-out">OpenCash</Link>, notre solution SaaS adaptée à vos besoins </h4>
                
                <p className="mb-2 text-gray-600 dark:text-gray-400 "><strong>Centralisez la gestion de vos flux</strong></p>
                <ul className='pt-2 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Intégration et saisie facile des prélèvements et virements.</li>
                  <li>Signature électronique avec certificats et parapheur de validation entièrement paramétrable (Simple ou Double).</li>
                </ul>

                <p className="mb-2 text-gray-600 dark:text-gray-400 "><strong>Fluidifiez la gestion de votre trésorerie</strong> </p>
                <ul className='pt-2 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Gestion de vos prévisions de trésorerie</li>
                  <li>Paramétrage et consultation de vos reportings personnalisés</li>
                  <li>Équilibrage de vos comptes bancaires</li>
                </ul>

                <p className="mb-2 text-gray-600 dark:text-gray-400 "><strong>Gardez le contrôle de vos comptes courants</strong></p>
                <ul className='pt-2 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Calcul de vos échelles d’intérêts </li>
                  <li>La gestion de votre facturation interne </li>
                  <li>Automatisez la génération des écritures comptables</li>
                </ul>

                <p className="mb-2 text-gray-600 dark:text-gray-400 "><strong>Optimisez la gestion de la dette</strong></p>
                <ul className='pt-2 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Gestion et pilotage de vos prêts-emprunts, lignes de crédits, SWAP de taux</li>
                  <li>Génération automatique des écritures comptables</li>
                  <li>Suivi des en-cours et intérêts courus non échus</li>
                </ul>

                <p className="mb-2 text-gray-600 dark:text-gray-400 "><strong>Optez pour une vision globale de vos placements</strong></p>
                <ul className='pt-2 pl-12 list-disc text-gray-600 dark:text-gray-400 mb-4'>
                  <li>Gestion et pilotage de vos comptes/dépôts à terme, comptes titres/rémunérés</li>
                  <li>Analyse de vos portefeuilles et rendements</li>
                  <li>Génération automatique des écritures comptables</li>
                </ul>

              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogSingleSaas;
