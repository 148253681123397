
import React, { useState } from 'react';

function PartnerCircles() {

  const [items] = useState([
    {
      // user-1.jpg
      size: '48', // image width / height
      style: { maxWidth: '7.08%', top: '29%', left: '2%' }, // image absolute  positioning
    },
    {
      // user-2.jpg
      size: '72',
      style: { maxWidth: '10.62%', top: '60%', left: '2%' },
    },
    {
      // user-3.jpg
      size: '72',
      style: { maxWidth: '10.62%', top: '43%', left: '13%' },
    },
    {
      // user-4.jpg
      size: '72',
      style: { maxWidth: '10.62%', top: '75%', left: '14%' },
    },
    {
      // user-5.jpg
      size: '64',
      style: { maxWidth: '9.44%', top: '11%', left: '15%' },
    },
    {
      // user-6.jpg
      size: '64',
      style: { maxWidth: '9.44%', top: '32%', left: '30%' },
    },
    {
      // user-7.jpg
      size: '50',
      style: { maxWidth: '7.37%', top: '65%', left: '30%' },
    },
    {
      // user-8.jpg
      size: '64',
      style: { maxWidth: '9.44%', top: '6%', left: '33%' },
    },
    {
      // user-9.jpg
      size: '64',
      style: { maxWidth: '9.44%', top: '77%', left: '44%' },
    },
    {
      // user-10.jpg
	  size: '80',
      style: { maxWidth: '11.8%', top: '48%', left: '46%' },
    },
    {
      // user-11.jpg
      size: '72',
      style: { maxWidth: '10.62%', top: '16%', left: '46%' },
    },
    {
      // user-12.jpg
      size: '64',
      style: { maxWidth: '9.44%', top: '65%', left: '58%' },
    },
    {
      // user-13.jpg
      size: '80',
      style: { maxWidth: '11.8%', top: '32%', left: '62%' },
    },
    {
      // user-14.jpg
      size: '48',
      style: { maxWidth: '7.08%', top: '6%', left: '63%' },
    },
    {
      // user-15.jpg
      size: '72',
      style: { maxWidth: '10.62%', top: '73%', left: '80%' },
    },
    {
      // user-16.jpg
      size: '44',
      style: { maxWidth: '6.49%', top: '59%', left: '76%' },
    },
    {
      // user-17.jpg
      size: '72',
      style: { maxWidth: '10.62%', top: '14%', left: '78%' },
    },
    {
      // user-18.jpg
      size: '64',
      style: { maxWidth: '9.44%', top: '40%', left: '89%' },
    },
  ]);

  return (
    <section className='relative' id='clients'>
      <div className="max-w-3xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
            <h2 className="h2 mb-4 font-libre" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Ils nous font confiance </h2>
          </div>
          <div className="relative" data-aos="fade-up">
            {/* Background image */}
            <svg className="mx-auto" viewBox="0 0 678 346" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="circle_b">
                  <stop stopColor="#BE1E3C" offset="0%" />
                  <stop stopColor="#BE1E3C" stopOpacity="0" offset="100%" />
                </radialGradient>
                <linearGradient x1="50%" y1="50%" x2="50%" y2="89.386%" id="circle_a">
                  <stop stopColor="#2E2E33" offset="0%" />
                  <stop stopColor="#2E2E33" stopOpacity="0" offset="100%" />
                </linearGradient>
              </defs>
              <g fill="none" fillRule="evenodd">
                <circle className="opacity-10 dark:opacity-100" fill="url(#circle_a)" opacity=".32" cx="339" cy="173" r="173" />
                <circle fill="url(#circle_b)" opacity=".32" cx="339" cy="173" r="140" />
              </g>
            </svg>
            {/* People pics */}
            {items.map((item, index) => (
              <img key={index} className="absolute rounded-full z-10 animate-float" style={item.style} src={require(`../images/logos/user-${index + 1}.jpg`).default} width={item.size} height={item.size} alt={`User ${index + 1}`} />
            ))}
          </div>
        </div>
      </div> 
    </section>
	
  );
}
export default PartnerCircles;