import React from 'react';

function OpenCashComponent02() {
  return (
  <section>
    <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div className="py-12 md:py-20">
        {/* Features */}
        <div className="grid gap-20">
          {/* 1st Feature */}
          <div className="md:grid md:grid-cols-12 md:gap-6 items-center" >
            <div className=" max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-left" data-aos-duration="1500"> 
              <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/OpenCash/1.jpg').default} width="400" alt='OC 1' />
            </div>
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-right" data-aos-duration="1500"> 
              <div className="md:pr-4 lg:pr-12 xl:pr-16">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4">OpenCash Flux</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                  <li>Gérez tous les flux bancaires.</li>
                  <li>Paramétrage de tous les formats XML, CFONB, plat et domestique.</li>
                  <li>Gérez vos chèques et LCR.</li>
                  <li>Gérez vos mandats.</li> 
                </ul>
              </div>
            </div>
          </div>
          {/* 2nd Feature*/}
          <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
            {/* Image */}
            <div className="items-left max-w-xl  md:w-full mx-0 md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-left" data-aos-duration="1500"> 
              <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/OpenCash/2.jpg').default} width="400" alt='OC 2' />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-left" data-aos-duration="1500"> 
              <div className="md:pl-4 lg:pl-12 xl:pl-16">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4">OpenCash Communication bancaire</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                  <li>Gestion de toutes les communications EBICS T,  EBICS TS, Swiftnet (alliance lite), SFTP, service bureau.</li>
                  <li>Mise à disposition d’API  Rest Full.</li>
                  <li>Chiffrement GPG.</li>   
                </ul>
              </div>
            </div>
          </div>
          {/* 3rd Feature */}
          <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
            {/* Image */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-left" data-aos-duration="1500"> 
              <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/OpenCash/3.jpg').default} width="400" alt='OC 3' />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-right" data-aos-duration="1500">
              <div className="md:pr-4 lg:pr-12 xl:pr-16">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4 ">OpenCash Sécurité</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                <li>Échanges sécurisées</li>
                 <li>Les 4 yeux sur les paramétrages.</li>
                  <li>Chiffrement GPG des fichiers.</li>
                    <li>Fraude monitor.</li>
                    <li>Signature électronique.</li>
                    <li>Authentification signature OTP.</li>
                    <li>Open ID connect.</li>
                 </ul>
              
              </div>  
            </div>
          </div>

          <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
            {/* Image */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-left" data-aos-duration="1500"> 
              <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/OpenCash/4.jpg').default} width="400" alt='OC 4' />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-left" data-aos-duration="1500"> 
              <div className="md:pl-4 lg:pl-12 xl:pl-16">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4">OpenCash Trésorerie </h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                  <li>Pilotez vos prévisions de trésorerie.</li>
                  <li>Contrôlez vos positions bancaires.</li>
                  <li>Équilibrez vos comptes via le cash-pooling.</li>
                  <li>Gérez votre dette.</li>   
                  <li>Suivez vos comptes courants.</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
            {/* Image */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-left" data-aos-duration="1500"> 
              <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg shadow-xl shadow-gray-600" src={require('../images/OpenCash/5.jpg').default} width="400" alt='OC 5' />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-6" data-aos="fade-right" data-aos-duration="1500">
              <div className="md:pr-4 lg:pr-12 xl:pr-16">
                <h3 className="h3 dark:text-gray-300 font-libre mb-4">OpenCash Comptabilité</h3>
                <ul className="list-disc text-gray-600 dark:text-gray-400 text-base md:text-xl pl-6">
                  <li>Interfacez OpenCash avec votre ERP comptable.</li>
                  <li>Gérez votre rapprochement bancaire. </li>
                  <li>Gagnez en efficacité et en sécurité en générant automatiquement vos écritures comptables.</li>
                </ul>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}
export default OpenCashComponent02;
