import React from 'react';

import Header from '../partials/Header';
import HeroOpenCash from '../partials/HeroOpenCash';
import OpenCashComponent01 from '../partials/OpenCashComponent01';
import OpenCashComponent02 from '../partials/OpenCashComponent02';

import ProcessOc from '../partials/ProcessOc';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';

function Cubicus() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">

        {/*  Page sections */}
        <HeroOpenCash />
        <OpenCashComponent01 />
        <OpenCashComponent02 />
        <ProcessOc />
        <Cta />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Cubicus;