import React from 'react';

function PageIllustration2() {
  return (
    <svg className="absolute top-0 left-0 transform -translate-x-1/2 -ml-8 dark:opacity-75" width="800" height="502" fill="none" xmlns="http://www.w3.org/2000/svg">
	  
      <circle cx="400" cy="102" r="500" fill="url(#heroglow_paint_radial)" fillOpacity=".4" />
    
      <defs>
        <radialGradient id="heroglow_paint_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 149 251) scale(315.089)">
          <stop stopColor="#52525A" />
          <stop offset="1" stopColor="#52525A" stopOpacity=".01" />
        </radialGradient>
        
      </defs>
    </svg>
  );
}
export default PageIllustration2;