import React from 'react';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import PageIllustration2 from '../partials/PageIllustration2';

import Footer from '../partials/Footer';

function Privacy(){
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
      
      {/*  Page content */}
        <main >
            <section className="relative">
                <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
                    <PageIllustration />
                    <PageIllustration2 />
                </div>
                <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                    <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                        <div className="lg:flex lg:justify-between" data-sticky-container>
                        {/* Page header */}
                        {/* Contact form */}
                            <div className="max-w-xl mx-auto" >
                                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                    <h1 className="h1 font-libre mb-4">Confidentialité</h1> 
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-5 items-center">
                                    <div className="w-full  px-3 mb-4 md:mb-0">
                                        <p className="h4 block mb-1 text-center font-libre">Protection de la vie privée sur Cubicus Mobile : </p>
                                        <ul className='list-disc mt-4 text-gray-600 dark:text-gray-400 items-center pl-4 text-xl'>
                                            <li>Des algorithmes de chiffrement et TLS pour sécuriser vos données.</li>
                                            <li>Pas de sauvegarde d'informations personnelles sur la navigation depuis Cubicus Mobile.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}
export default Privacy;